import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import { Button, Dropdown, Grid, Icon, Form, Modal as SemanticModal, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import Modal from "react-modal"; // Needed for react-image-lightbox
import Lightbox from "react-image-lightbox";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileInvoiceDollar,
  faInfo,
  faLock,
  faPaperclip,
  faPhoneAlt,
  faStickyNote,
  faUserCheck,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { faTabletAndroidAlt, faPrint, faKey, faSteeringWheel, faTag, faParking } from "@fortawesome/pro-solid-svg-icons";
import AlertContainer from "react-alert";
import { connect } from "react-redux";
import { saveAs } from "file-saver";

import AppointmentAdvisedCriticalHistory from "./AppointmentAdvisedCriticalHistory";
import AddAppointmentIntervention from "./AddAppointmentIntervention";
import AppointmentSnoozedItems from "./AppointmentSnoozedItems";
import AppointmentSteps from "./AppointmentSteps";
import AppointmentDetailLog from "./AppointmentDetailLog";
import AppointmentStatusTimeline from "./AppointmentStatusTimeline";
import AppointmentLists from "./AppointmentLists";
import AppointmentNote from "./AppointmentNote";
import AppointmentPin from "./AppointmentPin";
import AppointmentDate from "./AppointmentDate";
import DeskCheckinModal from "./DeskCheckinModal";
import DeskCheckInQuestionModal from "./DeskCheckInQuestionModal";
import AppointmentRestoreHandle from "./AppointmentRestoreHandle";
import { CarInspectionDate, DBBButton, InlineInputIcon, MCCButton, RDWDetail, visorify, CustomConfirm, FileDropDown, UserInput, ROBDetail } from "./../../components";
import CustomerModal from "./../Customers/CustomerModal";
import Can from "./../Can";
import { APPOINTMENT_NOTE_TYPES, formatDateIfValid } from "./util";
import IntegrationsService from "./../../integrations/service";
import { getYYYMMDD, removeGenerationParam, round2, validateEmail, SMS_GATEWAYS, copyToClipboard, DMS_CAPABILITIES, isAdmin, nextlaneEnabled } from "../../util/common";
import { COMMUNICATION_STATUSES, CHANNELS, COMMUNICATION_EVENTS, APPOINTMENT_STATUSES, QUESTION_RESULT_STATUS, STATUS_OVERRIDE } from "./common";
import { addAppointmentAttachment, deleteAppointmentAttachment, addIntervention, deleteIntervention } from "./store";
import { getPreference, setPreference } from "../../util/preferences";
import { ROLES } from "../Users/roles";
import printJS from "../../lib/print-js";
import ENV from "../../util/env-config";
import { logout } from "../Auth/store";
import { DMS } from "../DMSv3/util";

import Service from "./service";
import CarsService from "./../Cars/service";

import "./AppointmentDetail.css";

import "moment/locale/fr";
import "moment/locale/nl";

const getEventName = (type, t) => {
  let name = "";
  switch (type) {
    case COMMUNICATION_EVENTS.ONLINE_CHECKIN_SENT:
      name = t("sent_online_checkin").message || "Sent online checkin";
      break;

    case COMMUNICATION_EVENTS.ONLINE_CHECKIN_OPENED:
      name = t("opened_online_checkin").message || "Opened online checkin";
      break;

    case COMMUNICATION_EVENTS.ONLINE_CHECKIN_ANSWERED:
      name = t("answered_online_checkin").message || "Answered online checkin";
      break;

    case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_SENT:
      name = t("sent_diagnose_overview").message || "Sent diagnose overview";
      break;

    case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_OPENED:
      name = t("opened_diagnose_overview").message || "Opened diagnose overview";
      break;

    case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED:
      name = t("answered_diagnose_overview").message || "Answered diagnose overview";
      break;

    case COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_RESENT:
      name = t("resent_diagnose_overview").message || "Resent diagnose overview";
      break;

    case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_SENT:
      name = t("sent_repair_overview").message || "Sent repair overview";
      break;

    case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_RESENT:
      name = t("resent_repair_overview").message || "Resent repair overview";
      break;

    case COMMUNICATION_EVENTS.REPAIR_OVERVIEW_OPENED:
      name = t("repair_overview_opened").message || "Repair overview opened";
      break;

    default:
      break;
  }
  return name;
};

const KEY_LABEL_PRINT_SIZES = {
  A4: 1,
  LABEL: 2,
  KIOSK_LABEL: 3,
};

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);
    const { appointment, currentUser, appointmentChecks } = props;

    window.ZohoHCAsapReady &&
      window.ZohoHCAsapReady(() => {
        window.ZohoHCAsap.Action("hideLauncher");
      });

    let selectedCustomerComm = [];
    let autoSelectedQuestions = false;
    let checksAddedToCustomcom = [];

    if (Array.isArray(appointment.repair_overview_results) && appointment.repair_overview_results.length > 0) {
      selectedCustomerComm = appointment.repair_overview_results.map(r => {
        return {
          question_result_id: r.question_result_id,
        };
      });
    } else if (Array.isArray(appointment.diagnose_overview_results) && appointment.diagnose_overview_results.length > 0) {
      selectedCustomerComm = appointment.diagnose_overview_results.map(r => {
        return {
          question_result_id: r.question_result_id,
        };
      });

      if (appointment.customcom_status < COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT && this.isCarReadyOrQualityCheckDone(appointment.appointment_status_identifier)) {
        autoSelectedQuestions = true;
      }
    } else if (Array.isArray(appointmentChecks)) {
      appointmentChecks.forEach(check => {
        if (!checksAddedToCustomcom.includes(check.id)) {
          checksAddedToCustomcom.push(check.id);
          if (
            check.checklist.template &&
            (check.checklist.template.cc_default_selected || check.checklist.template.cc_default_selected_nvt) &&
            Array.isArray(check.question_items)
          ) {
            check.question_items.forEach(item => {
              if (item.status !== QUESTION_RESULT_STATUS.NOT_WORKED_ON && check.checklist.template.cc_default_selected) {
                selectedCustomerComm.push({ question_result_id: item.id });
              } else if (item.status === QUESTION_RESULT_STATUS.NOT_WORKED_ON && check.checklist.template.cc_default_selected_nvt) {
                selectedCustomerComm.push({ question_result_id: item.id });
              }
            });
          }
        }
      });

      autoSelectedQuestions = selectedCustomerComm.length > 0;
    }

    const last_event =
      Array.isArray(appointment.communication_events) &&
      appointment.communication_events
        .filter(c => Array.isArray(c.receivers) && c.receivers.length > 0)
        .sort((ev1, ev2) => {
          if (ev1.created_on > ev2.created_on) return -1;
          else if (ev1.created_on < ev2.created_on) return 1;
          else return 0;
        })
        .find(item => item.receivers.some(r => r.destination !== ""));

    const last_email_receiver = last_event?.receivers?.find(r => r.channel === CHANNELS.EMAIL && r.destination);
    const last_SMS_receiver = last_event?.receivers?.find(r => r.channel === CHANNELS.SMS && r.destination);

    const originalCustomerID =
      appointment.customcom_customer_id > 0 ? this.props.appointment.customcom_customer_id : last_email_receiver?.destination ? "switch-to-manual-email" : null;

    const originalCustomerEmail = last_email_receiver ? last_email_receiver.destination : "";
    const originalCustomerPhone = last_SMS_receiver ? last_SMS_receiver.destination : "";

    let manualEmailSelected = originalCustomerID === "switch-to-manual-email" && last_email_receiver?.destination;

    if (
      !manualEmailSelected &&
      originalCustomerEmail &&
      last_event?.customer &&
      originalCustomerEmail !== last_event.customer.email_private &&
      originalCustomerEmail !== last_event.customer.email_business
    ) {
      manualEmailSelected = true;
    }

    this.state = {
      appointment,
      appointmentChecks: appointmentChecks,
      images: null,
      isAddInterventionVisible: false,
      isConvertToInterventionVisible: null,
      isCarReadyConfirmVisible: false,
      isSendingOnlineCheckinConfirmationVisible: false,
      isSendingOnlineCheckin: false,
      photoIndex: 0,
      statusHistory: this.mergeStatusHistoryAndEvents(props),
      customerID: null,
      showNextDateSaveIcon: false,
      showTimeCarAppSaveIcon: false,
      showCarReadyTimeSaveIcon: false,
      countUnsavedChanges: 0,
      isUnsavedConfirmVisible: false,
      isEditable: this.isEditable(appointment.appointment_status_identifier, currentUser),
      wo_widget: "",
      button_label: "S",
      isCustomerCommunicationVisible: false,
      isDeskCommunicationVisible: false,
      selectedDeskComm: appointment.desk_communications?.length ? appointment.desk_communications[appointment.desk_communications.length - 1].results : [],
      selectedCustomerComm,
      checksAddedToCustomcom,
      originalSelectedCustomerComm: autoSelectedQuestions ? [] : [...selectedCustomerComm],
      questionChanged: autoSelectedQuestions,
      receiverChanged: false,
      originalCustomerID,
      selectedCustomerID: originalCustomerID,
      customCommNote: appointment.communication_note,
      appointmentNote: null,
      noteChanged: false,
      originalCustomCommNote: appointment.communication_note,
      originalCustomerEmail,
      selectedCustomerEmail: originalCustomerEmail,
      manualEmailSelected,
      manuallyEnteredEmail: manualEmailSelected ? last_email_receiver.destination : "",
      invalidEmail: false,
      customerPhoneOptions: [], // TODO this should probably be initialized here ?
      originalCustomerPhone,
      selectedCustomerPhone: originalCustomerPhone,
      alertOptions: {
        offset: 20,
        position: "top right",
        theme: "light",
        time: 10000,
        transition: "fade",
      },
      alertOptions2: {
        offset: 20,
        position: "top center",
        theme: "light",
        time: 10000,
        transition: "fade",
      },
      isOpenConfirmAppointmentCancel: false,
      isOpenConfirmInterventionDelete: false,
      originalNextDate: false,
      originalCarReturnTime: false,
      cancelAppointmentError: "",
      pdfVisible: false,
      loadingPreview: false,
      downloadingPDF: false,
      refreshingDMS: false,
      isAddingNewIntervention: false,
      isDeletingIntervention: false,
      isCancelingAppointment: false,
      addInterventionsError: "",
      registerTabletModalOpen: false,
      deskCheckinModalOpen: false,
      carInShopPrintVisible: false,
      emailOptions: [],
      emailOptionSelected: "",
      phoneOptions: [],
      phoneOptionSelected: "",
      confirmReasons: [],
      keyLabelPrintSize: getPreference("key-label-print-size", KEY_LABEL_PRINT_SIZES.A4),
      displayEnterCurrentCarMileage: false,
      current_mileage: 0,
      isUpdatingAppointmentStatus: false,
      rdwDetails: {},
      originalTimeCarApp: false,
      isRestoringAppointment: false,
      kioskLabelModalOpened: false,
      isDetachingLabel: false,
      detachLabelError: "",
    };
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.props.getChecks(this.props.appointment.id);
    this.setupUnsavedGuard();

    this.getWOWidget();
    this.getCancellationReasons();

    this.getEmailOptions();
    this.getPhoneOptions();
    this.getRDWDetails();
  }

  componentWillUnmount() {
    this.removeUnsavedGuard();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appointment) {
      const { appointment, appointmentChecks, currentUser } = nextProps;

      this.setState({
        appointment: nextProps.appointment,
        statusHistory: this.mergeStatusHistoryAndEvents(nextProps),
        isEditable: this.isEditable(appointment.appointment_status_identifier, currentUser),
      });

      let selectedCustomerComm = [];
      let autoSelectedQuestions = false;
      let newCheck = false;
      let { checksAddedToCustomcom } = this.state;
      if (Array.isArray(appointment.repair_overview_results) && appointment.repair_overview_results.length > 0) {
        selectedCustomerComm = appointment.repair_overview_results.map(r => {
          return {
            question_result_id: r.question_result_id,
          };
        });
      } else if (Array.isArray(appointment.diagnose_overview_results) && appointment.diagnose_overview_results.length > 0) {
        selectedCustomerComm = appointment.diagnose_overview_results.map(r => {
          return {
            question_result_id: r.question_result_id,
          };
        });

        if (appointment.customcom_status < COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT && this.isCarReadyOrQualityCheckDone(appointment.appointment_status_identifier)) {
          autoSelectedQuestions = true;
        }
      } else if (Array.isArray(appointmentChecks)) {
        selectedCustomerComm = this.state.selectedCustomerComm ? [...this.state.selectedCustomerComm] : [];
        appointmentChecks.forEach(check => {
          if (!checksAddedToCustomcom.includes(check.id)) {
            checksAddedToCustomcom.push(check.id);
            if (
              check.checklist.template &&
              (check.checklist.template.cc_default_selected || check.checklist.template.cc_default_selected_nvt) &&
              Array.isArray(check.question_items)
            ) {
              newCheck = true;
              check.question_items.forEach(item => {
                if (item.status !== QUESTION_RESULT_STATUS.NOT_WORKED_ON && check.checklist.template.cc_default_selected) {
                  selectedCustomerComm.push({ question_result_id: item.id });
                } else if (item.status === QUESTION_RESULT_STATUS.NOT_WORKED_ON && check.checklist.template.cc_default_selected_nvt) {
                  selectedCustomerComm.push({ question_result_id: item.id });
                }
              });
            }
          }
        });
      }

      let { questionChanged, originalSelectedCustomerComm } = this.state;
      if (!questionChanged || newCheck) {
        if (
          !_.isEqual(
            originalSelectedCustomerComm.sort((x, y) => {
              if (x.question_result_id < y.question_result_id) return 1;
              else if (x.question_result_id > y.question_result_id) return -1;
              else return 0;
            }),

            selectedCustomerComm.sort((x, y) => {
              if (x.question_result_id < y.question_result_id) return 1;
              else if (x.question_result_id > y.question_result_id) return -1;
              else return 0;
            })
          )
        ) {
          this.setState({
            selectedCustomerComm,
            originalSelectedCustomerComm: autoSelectedQuestions ? [] : [...this.state.selectedCustomerComm],
            questionChanged: true,
            checksAddedToCustomcom,
          });
        }
      }

      if (appointment.communication_note !== this.state.originalCustomCommNote) {
        this.setState({
          originalCustomCommNote: nextProps.appointment.communication_note,
          customCommNote: nextProps.appointment.communication_note,
        });
      }

      const last_event =
        Array.isArray(appointment.communication_events) &&
        appointment.communication_events
          .filter(c => Array.isArray(c.receivers) && c.receivers.length > 0)
          .sort((ev1, ev2) => {
            if (ev1.created_on > ev2.created_on) return -1;
            else if (ev1.created_on < ev2.created_on) return 1;
            else return 0;
          })
          .find(item => item.receivers.some(r => r.destination !== ""));

      const last_email_receiver = last_event?.receivers?.find(r => r.channel === CHANNELS.EMAIL && r.destination);
      const last_SMS_receiver = last_event?.receivers?.find(r => r.channel === CHANNELS.SMS && r.destination);

      const originalCustomerID =
        appointment.customcom_customer_id > 0 ? this.props.appointment.customcom_customer_id : last_email_receiver?.destination ? "switch-to-manual-email" : null;

      const originalCustomerEmail = last_email_receiver ? last_email_receiver.destination : "";
      const originalCustomerPhone = last_SMS_receiver ? last_SMS_receiver.destination : "";

      let manualEmailSelected = originalCustomerID === "switch-to-manual-email" && last_email_receiver?.destination;

      if (
        !manualEmailSelected &&
        originalCustomerEmail &&
        last_event?.customer &&
        originalCustomerEmail !== last_event.customer.email_private &&
        originalCustomerEmail !== last_event.customer.email_business
      ) {
        manualEmailSelected = true;
      }

      if (
        originalCustomerID !== this.state.originalCustomerID ||
        originalCustomerEmail !== this.state.originalCustomerEmail ||
        originalCustomerPhone !== this.state.originalCustomerPhone
      ) {
        this.setState({
          originalCustomerID,
          selectedCustomerID: originalCustomerID,
          originalCustomerEmail,
          selectedCustomerEmail: originalCustomerEmail,
          manualEmailSelected,
          manuallyEnteredEmail: manualEmailSelected ? last_email_receiver.destination : "",
          invalidEmail: false,
          originalCustomerPhone,
          selectedCustomerPhone: originalCustomerPhone,
        });
      }

      if (
        nextProps.appointment.appointment_status_identifier !== this.props.appointment.appointment_status_identifier &&
        nextProps.appointment.appointment_status_identifier === APPOINTMENT_STATUSES.NEW_CAR &&
        !this.state.confirmReasons.length
      ) {
        this.getCancellationReasons();
      }
    }

    if (nextProps.appointmentChecks && this.props.appointmentChecks !== nextProps.appointmentChecks) {
      const selectedDeskComm = nextProps.appointmentChecks.flatMap(check =>
        check.question_items?.filter(item => item.customer_approved).map(item => ({ question_result_id: item.id }))
      );

      this.setState({ appointmentChecks: nextProps.appointmentChecks, selectedDeskComm });
    }
  }

  isCarReadyOrQualityCheckDone = appointment_status_identifier => {
    return (
      appointment_status_identifier === APPOINTMENT_STATUSES.CAR_READY ||
      appointment_status_identifier === APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW ||
      appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK ||
      appointment_status_identifier === APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW
    );
  };

  isEditable = (appointment_status_identifier, currentUser) => {
    return (
      !this.isCarReadyOrQualityCheckDone(appointment_status_identifier) &&
      appointment_status_identifier !== APPOINTMENT_STATUSES.CANCELED &&
      ![ROLES.SUPERVISOR, ROLES.MANUFACTURER, ROLES.MECHANIC].includes(currentUser.role_id)
    );
  };

  isPriceEditable = currentUser => ![ROLES.SUPERVISOR, ROLES.MANUFACTURER, ROLES.MECHANIC].includes(currentUser.role_id);

  getCancellationReasons = () => {
    if (this.state.appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.NEW_CAR) return;
    Service.getCancelAppointmentReasons()
      .then(response => {
        const confirmReasons = response?.data?.data?.length ? response.data.data : [];
        this.setState({ confirmReasons });
      })
      .catch(error => {
        console.error("Error saving the appointment updates.", error);
        error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
      });
  };

  mergeStatusHistoryAndEvents = props => {
    const {
      status_history,
      car_in_shop,
      car_in_shop_set_by,
      car_out_of_shop,
      car_out_of_shop_set_by,
      key_picked_up_at,
      key_picked_up_by,
      key_dropped_at,
      key_dropped_back_at,
      key_dropped_back_by,
      key_picked_up_back_at,
      key_picked_up_back_by,
      sharebox_key_picked_up_at,
      sharebox_key_picked_up_by,
      sharebox_key_dropped_at,
      sharebox_key_dropped_back_at,
      sharebox_key_dropped_back_by,
      sharebox_key_picked_up_back_at,
      sharebox_key_picked_up_back_by,
      acses_key_dropped_at,
      acses_key_picked_up_at,
      acses_key_pick_up_by,
      acses_key_dropped_back_at,
      acses_key_drop_back_by,
      acses_key_picked_up_back_at,
      check_paused_at,
      customer_owner_name,
    } = props.appointment;
    let { communication_events, desk_communications } = props.appointment;
    const { t } = props;

    let statusHistory = [];

    communication_events = Array.isArray(communication_events) && communication_events.length ? communication_events : null;

    if (communication_events) {
      let last_customer;

      communication_events = communication_events.sort((a, b) => (a.created_on < b.created_on ? -1 : a.created_on > b.created_on ? 1 : 0));

      statusHistory = communication_events.map(comm => {
        let user = "";
        if (comm.user?.fullname) user = comm.user.fullname;
        else if (comm.user?.first_name || comm.user?.last_name) user = (comm.user.first_name + " " + comm.user.last_name).trim();
        else if (comm.customer?.firstname || comm.customer?.surname) user = (comm.customer.firstname + " " + comm.customer.surname).trim();
        else if (last_customer) user = (last_customer.firstname + " " + last_customer.surname).trim();

        if (comm.customer?.firstname || comm.customer?.surname) last_customer = comm.customer;

        return {
          sa_remarks: getEventName(comm.type, t),
          identifier: comm.type === COMMUNICATION_EVENTS.DIAGNOSE_OVERVIEW_ANSWERED ? APPOINTMENT_STATUSES.CUSTOMER_ANSWERED : APPOINTMENT_STATUSES.CONTACT,
          commType: comm.type,
          user_id: comm.user?.id ? comm.user.id : null,
          last_user: user,
          customer: comm.customer,
          receivers: comm.receivers,
          timestamp: comm.created_on,
          box_number: comm.box_number,
        };
      });
    }

    if (desk_communications?.length) {
      desk_communications.forEach(dc => {
        statusHistory.push({
          sa_remarks: dc.is_checking_out ? t("sent_desk_checkout").message || "Sent desk checkout" : t("sent_desk_checkin").message || "Sent desk checkin",
          identifier: APPOINTMENT_STATUSES.CUSTOMER_ANSWERED,
          user_id: dc.user?.id ? dc.user.id : null,
          last_user: `${dc?.user?.firstname || ""} ${dc?.user?.surname || ""}`,
          customer: dc.customer,
          timestamp: dc.created_on,
        });
      });
    }

    if (car_in_shop) {
      statusHistory.push({
        sa_remarks: t("car_in_shop").message || "Car in shop",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: car_in_shop,
        last_user: customer_owner_name ? customer_owner_name : car_in_shop_set_by ? car_in_shop_set_by.first_name + " " + car_in_shop_set_by.last_name : "",
      });
    }

    if (car_out_of_shop) {
      statusHistory.push({
        sa_remarks: t("car_out_of_shop").message || "Car out of shop",
        identifier: APPOINTMENT_STATUSES.CAR_OUT_OF_SHOP,
        timestamp: car_out_of_shop,
        last_user: car_out_of_shop_set_by ? car_out_of_shop_set_by.first_name + " " + car_out_of_shop_set_by.last_name : "",
      });
    }

    if (Array.isArray(status_history) && status_history.length > 0) {
      for (let status of status_history) {
        if (
          status.identifier !== APPOINTMENT_STATUSES.CUSTOMER_ANSWERED &&
          (status.identifier !== APPOINTMENT_STATUSES.CONTACT || !communication_events?.includes(COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT))
        ) {
          statusHistory.push(status);
        }
      }
    }

    if (check_paused_at) {
      statusHistory.push({
        sa_remarks: t("paused_check").message || "Paused check",
        identifier: APPOINTMENT_STATUSES.CAR_CHECK_STARTED,
        timestamp: check_paused_at,
      });
    }

    if (key_dropped_at) {
      statusHistory.push({
        sa_remarks: t("keylocker_key_dropped").message || "Key dropped",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: key_dropped_at,
      });
    }

    if (key_picked_up_at) {
      statusHistory.push({
        sa_remarks: t("keylocker_key_picked_up").message || "Key picked up",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: key_picked_up_at,
        last_user: key_picked_up_by ? key_picked_up_by.first_name + " " + key_picked_up_by.last_name : "",
      });
    }

    if (key_dropped_back_at) {
      statusHistory.push({
        sa_remarks: t("keylocker_key_dropped_back").message || "Key dropped back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: key_dropped_back_at,
        last_user: key_dropped_back_by ? key_dropped_back_by.first_name + " " + key_dropped_back_by.last_name : "",
      });
    }

    if (key_picked_up_back_at) {
      statusHistory.push({
        sa_remarks: t("keylocker_key_picked_up_back").message || "Key picked up back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: key_picked_up_back_at,
        last_user: key_picked_up_back_by ? key_picked_up_back_by.first_name + " " + key_picked_up_back_by.last_name : "",
      });
    }

    if (sharebox_key_dropped_at) {
      statusHistory.push({
        sa_remarks: t("sharebox_key_dropped").message || "Sharebox key dropped",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: sharebox_key_dropped_at,
      });
    }

    if (sharebox_key_picked_up_at) {
      statusHistory.push({
        sa_remarks: t("sharebox_key_picked_up").message || "Sharebox key picked up",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: sharebox_key_picked_up_at,
        last_user: sharebox_key_picked_up_by ? sharebox_key_picked_up_by.first_name + " " + sharebox_key_picked_up_by.last_name : "",
      });
    }

    if (sharebox_key_dropped_back_at) {
      statusHistory.push({
        sa_remarks: t("sharebox_key_dropped_back").message || "Sharebox key dropped back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: sharebox_key_dropped_back_at,
        last_user: sharebox_key_dropped_back_by ? sharebox_key_dropped_back_by.first_name + " " + sharebox_key_dropped_back_by.last_name : "",
      });
    }

    if (sharebox_key_picked_up_back_at) {
      statusHistory.push({
        sa_remarks: t("sharebox_key_picked_up_back").message || "Sharebox key picked up back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: sharebox_key_picked_up_back_at,
        last_user: sharebox_key_picked_up_back_by ? sharebox_key_picked_up_back_by.first_name + " " + sharebox_key_picked_up_back_by.last_name : "",
      });
    }

    if (acses_key_dropped_at) {
      statusHistory.push({
        sa_remarks: t("acses_key_dropped").message || "ACSES key dropped",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: acses_key_dropped_at,
      });
    }

    if (acses_key_picked_up_at) {
      statusHistory.push({
        sa_remarks: t("acses_key_picked_up").message || "ACSES key picked up",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: acses_key_picked_up_at,
        last_user: acses_key_pick_up_by ? acses_key_pick_up_by.first_name + " " + acses_key_pick_up_by.last_name : "",
      });
    }

    if (acses_key_dropped_back_at) {
      statusHistory.push({
        sa_remarks: t("acses_key_dropped_back").message || "ACSES key dropped back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: acses_key_dropped_back_at,
        last_user: acses_key_drop_back_by ? acses_key_drop_back_by.first_name + " " + acses_key_drop_back_by.last_name : "",
      });
    }

    if (acses_key_picked_up_back_at) {
      statusHistory.push({
        sa_remarks: t("acses_key_picked_up_back").message || "ACSES key picked up back",
        identifier: APPOINTMENT_STATUSES.CAR_IN_SHOP,
        timestamp: acses_key_picked_up_back_at,
      });
    }

    if (statusHistory.length > 0) statusHistory.sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));

    return statusHistory;
  };

  error = msg => {
    this.msg.show(msg, { type: "error" });
  };

  renderAlert = () => {
    return <AlertContainer ref={a => (this.msg = a)} {...this.state.alertOptions} />;
  };

  renderAlertCustomcom = () => {
    return <AlertContainer ref={a => (this.msgCustomcom = a)} {...this.state.alertOptions2} />;
  };

  renderAlertPreview = () => {
    return <AlertContainer ref={a => (this.msgPreview = a)} {...this.state.alertOptions2} />;
  };

  renderAlertSuccessCopy = () => {
    return <AlertContainer ref={a => (this.msgSuccessCopy = a)} {...this.state.alertOptions2} />;
  };

  removeUnsavedGuard = () => {
    document.removeEventListener("inputChanged", this.handleInputChange);
  };

  setupUnsavedGuard = () => {
    document.addEventListener("inputChanged", this.handleInputChange);
  };

  handleInputChange = e => {
    let { countUnsavedChanges } = this.state;
    if (e.detail) {
      countUnsavedChanges += 1;
    } else {
      if (countUnsavedChanges > 0) {
        countUnsavedChanges -= 1;
      }
    }
    this.setState({ countUnsavedChanges });
  };
  handleHideCarReadyConfirm = () => {
    this.setState({ isCarReadyConfirmVisible: false });
  };

  handleCarReadyConfirm = () => {
    this.handleUpdate({ type: "step", value: APPOINTMENT_STATUSES.CAR_READY, shouldSave: true });
    this.setState({ isCarReadyConfirmVisible: false });
  };

  // Handles updating of appointment details such as mileage, dates, statuses
  handleUpdate = detail => {
    let {
      showNextDateSaveIcon,
      showCarReadyTimeSaveIcon,
      appointment,
      originalNextDate,
      originalCarReturnTime,
      current_mileage,
      showTimeCarAppSaveIcon,
      originalTimeCarApp,
    } = this.state;
    const { selectedDealer, selectedLocation } = this.props.globalState;

    let updateBody = {
      id: appointment.id,
    };

    switch (detail.type) {
      case "car":
        appointment[detail.subtype] = parseInt(detail.value, 10);
        break;
      case "next_km":
      case "current_km":
        appointment[detail.type] = detail.value;

        break;
      case "date":
        appointment[detail.subtype] = detail.value;
        showTimeCarAppSaveIcon = detail.subtype === "time_car_app" && originalTimeCarApp ? true : showTimeCarAppSaveIcon;
        showNextDateSaveIcon = detail.subtype === "next_date" && originalNextDate ? true : showNextDateSaveIcon;
        showCarReadyTimeSaveIcon = detail.subtype === "car_return_time" && originalCarReturnTime ? true : showCarReadyTimeSaveIcon;
        break;
      case "step":
        if (detail.value === APPOINTMENT_STATUSES.CAR_READY && !detail.shouldSave) {
          // Show confirmation dialog for Car OK statu
          this.setState({ isCarReadyConfirmVisible: true });
          return;
        }

        if (detail.value !== APPOINTMENT_STATUSES.CAR_IN_SHOP) {
          updateBody.new_status_identifier = detail.value;
          appointment.appointment_status_identifier = detail.value;
        } else {
          if (selectedDealer.keyloop_contract_code && selectedLocation.keyloop_business_unit && !appointment.current_km) {
            if (!current_mileage) {
              this.setState({ displayEnterCurrentCarMileage: true });
              return;
            }

            appointment["current_km"] = parseInt(current_mileage);
            updateBody["current_km"] = parseInt(current_mileage);
          }

          if (detail.car_in_shop) {
            updateBody.car_in_shop = detail.car_in_shop;
            appointment.car_in_shop = detail.car_in_shop;
            appointment.car_out_of_shop = null;
          } else if (detail.car_out_of_shop) {
            updateBody.car_out_of_shop = detail.car_out_of_shop;
            appointment.car_out_of_shop = detail.car_out_of_shop;

            if (appointment.kiosk_label_number > 0) this.toggleKioskLabelModal();
          }
        }
        break;
      case "special-indicator":
        updateBody[detail.subtype] = !detail.value; // toggles special indicator
        appointment[detail.subtype] = !detail.value;
        break;
      case "shop-color":
        updateBody.is_shop_color = detail.value ? detail.value : "";
        appointment.is_shop_color = detail.value ? detail.value : "";
        updateBody.is_shop = !!detail.value;
        appointment.is_shop = !!detail.value;
        break;
      case "star-color":
        updateBody.is_star_color = detail.value ? detail.value : "";
        appointment.is_star_color = detail.value ? detail.value : "";
        updateBody.is_star = !!detail.value;
        appointment.is_star = !!detail.value;
        break;
      case "info-status":
        updateBody["new_status_identifier"] = APPOINTMENT_STATUSES.DIAGNOSE;
        updateBody["sa_remarks"] = detail.remark;
        break;
      default:
        break;
    }

    this.setState({ appointment, showNextDateSaveIcon, showCarReadyTimeSaveIcon, showTimeCarAppSaveIcon });

    if (!detail.shouldSave) {
      return;
    }

    if (detail.type === "time_car_app" && originalTimeCarApp) {
      if (moment(appointment.time_car_app).isSameOrAfter(moment(originalTimeCarApp).add(selectedLocation.check_in_days_prior, "days"))) {
        this.setState({ isSendingOnlineCheckinConfirmationVisible: true });
        return;
      }
    }

    if (detail.shouldSave && detail.type) {
      if (!["special-indicator", "info-status", "shop-color"].includes(detail.type)) {
        updateBody[detail.type] = appointment[detail.type];
      }
    }

    // Make sure something is actually updated before sending a request
    if (Object.keys(updateBody).length === 1) {
      return;
    }

    if (detail.type === "step") {
      this.setState({ isUpdatingAppointmentStatus: true });
    }

    Service.updateAppointment(updateBody)
      .then(result => {
        if ("time_car_app" in updateBody) {
          showTimeCarAppSaveIcon = false;
          originalTimeCarApp = false;
        }

        if ("next_date" in updateBody) {
          showNextDateSaveIcon = false;
          originalNextDate = false;
        }

        if ("car_return_time" in updateBody) {
          showCarReadyTimeSaveIcon = false;
          originalCarReturnTime = false;
        }

        let { isEditable } = this.state;
        if ("new_status_identifier" in updateBody) {
          isEditable = this.isEditable(updateBody["new_status_identifier"], this.props.currentUser);
          if (updateBody["new_status_identifier"] === APPOINTMENT_STATUSES.CAR_READY) appointment.customcom_status = COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED;
        }

        this.setState(prevState => ({
          showNextDateSaveIcon,
          showCarReadyTimeSaveIcon,
          originalNextDate,
          originalCarReturnTime,
          isEditable,
          showTimeCarAppSaveIcon,
          originalTimeCarApp,
          isUpdatingAppointmentStatus: false,
          countUnsavedChanges:
            detail.type !== "step" &&
            detail.type !== "special-indicator" &&
            detail.type !== "shop-color" &&
            detail.type !== "info-status" &&
            prevState.countUnsavedChanges > 0
              ? prevState.countUnsavedChanges - 1
              : prevState.countUnsavedChanges,
        }));
      })
      .catch(error => {
        console.error("Error saving the appointment updates.", error);
        error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
        this.setState({ isUpdatingAppointmentStatus: false });
      });
  };

  handleCustomCom = ({ remark }) => {
    const { id } = remark;

    const { diagnose_overview_remarks } = this.state.appointment;
    if (Array.isArray(diagnose_overview_remarks) && diagnose_overview_remarks.length) {
      let idx = diagnose_overview_remarks.findIndex(r => r.id === id);
      if (idx > -1) {
        const isCustomComRemarkHandled = !diagnose_overview_remarks[idx].receptionist_handled;
        Service.handleCustomComRemark({
          diagnose_overview_remark_id: id,
          handled_remark: isCustomComRemarkHandled,
        })
          .then(result => {
            const appointment = { ...this.state.appointment };
            appointment.diagnose_overview_remarks[idx].receptionist_handled = isCustomComRemarkHandled;
            this.setState({ appointment });
          })
          .catch(error => {
            console.log("could not toggle diagnose overview reamrk", error);
            error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          });
      }
    }
  };

  handleCheckInCom = ({ result, remark, openModalConvertToIntervention }) => {
    if (result?.id) {
      let { check_in_results } = this.state.appointment;
      let idx = check_in_results.findIndex(r => r.id === result.id);
      if (idx > -1) {
        const isCheckInResultHandled = !check_in_results[idx].receptionist_handled;
        Service.handleCheckInResult({
          checkin_result_id: result.id,
          handled: isCheckInResultHandled,
        })
          .then(() => {
            const appointment = { ...this.state.appointment };
            appointment.check_in_results[idx].receptionist_handled = isCheckInResultHandled;
            this.setState({ appointment, addInterventionErrors: "", isConvertToInterventionVisible: openModalConvertToIntervention ? { result } : null });
          })
          .catch(error => {
            console.log("could not toggle check in result", error);
            error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          });
      }
    } else if (remark.id) {
      let { check_in_remarks } = this.state.appointment;
      let idx = check_in_remarks.findIndex(r => r.id === remark.id);
      if (idx > -1) {
        const isCheckInRemarkHandled = !check_in_remarks[idx].receptionist_handled;
        Service.handleCheckInRemark({
          checkin_remark_id: remark.id,
          handled: isCheckInRemarkHandled,
        })
          .then(() => {
            const appointment = { ...this.state.appointment };
            appointment.check_in_remarks[idx].receptionist_handled = isCheckInRemarkHandled;
            this.setState({ appointment, addInterventionsError: "", isConvertToInterventionVisible: openModalConvertToIntervention ? { remark } : null });
          })
          .catch(error => {
            console.log("could not toggle check in reamrk", error);
            error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          });
      }
    }
  };

  handleKeyLockerRemark = ({ remark }) => {
    const { appointment } = this.state;

    remark.receptionist_handled = !remark.receptionist_handled;

    Service.handleKeyLockerRemark({
      keylocker_remark_id: remark.id,
      handled_remark: remark.receptionist_handled,
    })
      .then(res => {
        const keylocker_communications = appointment.keylocker_communications.map(com => {
          if (com.remark.id === remark.id) return { ...com, remark };
          return com;
        });

        this.setState({ appointment: { ...appointment, keylocker_communications } });
      })
      .catch(err => {
        console.error("could not toggle keylocker remark", err);
        err?.response?.data?.errors?.length > 0 && this.error(err.response.data.errors[0]);
      });
  };

  handleKioskRemark = ({ remark }) => {
    const { appointment } = this.state;

    if (remark) {
      remark.receptionist_handled = !remark.receptionist_handled;

      Service.handleKioskRemark({
        remark_id: remark.id,
        handled_remark: remark.receptionist_handled,
      })
        .then(res => {
          const kiosk_communications = appointment.kiosk_communications.map(com => {
            if (com.remark?.id === remark.id) return { ...com, remark };
            return com;
          });

          this.setState({ appointment: { ...appointment, kiosk_communications } });
        })
        .catch(err => {
          console.error("could not toggle keylocker remark", err);
          err?.response?.data?.errors?.length > 0 && this.error(err.response.data.errors[0]);
        });
    }
  };

  // Handles updating of intervention question item
  handleInterventionAnswerUpdate = updatedIntervention => {
    let { countUnsavedChanges } = this.state;
    if (countUnsavedChanges > 0) {
      countUnsavedChanges--;
    }

    const interventions = [...this.state.appointment.interventions];

    const interventionIndex = interventions.findIndex(intervention => intervention.id === updatedIntervention.id);
    if (interventionIndex > -1) interventions[interventionIndex] = updatedIntervention;

    Service.updateIntervention(updatedIntervention)
      .then(result => {
        this.setState({ countUnsavedChanges, appointment: { ...this.state.appointment, interventions } });
      })
      .catch(error => {
        error.response?.data?.errors && this.error(error.response.data.errors[0]);
      });
  };

  handleShowAddIntervention = () => {
    this.setState({ isAddInterventionVisible: true, addInterventionsError: "" });
  };

  handleHideAddIntervention = () => {
    this.setState({ isAddInterventionVisible: false });
  };

  handleHideConvertToIntervention = () => {
    this.setState({ isConvertToInterventionVisible: null });
  };

  handleAddIntervention = newIntervention => {
    this.setState({ isAddingNewIntervention: true }, () => {
      const { isConvertToInterventionVisible } = this.state;

      Service.addIntervention({
        ...newIntervention,
        checkin_result_id: isConvertToInterventionVisible?.result?.id || null,
        checkin_remark_id: isConvertToInterventionVisible?.remark?.id || null,
      })
        .then(result => {
          if (result?.data?.data) {
            const interventionInAppointment = this.state.appointment.interventions?.find(i => i.id === result.data.data.id);

            if (!interventionInAppointment) {
              newIntervention.id = result.data.data.id;
              newIntervention.created_on = moment().utc().format("YYYY-MM-DDTHH:mm:ssZ");
              newIntervention.elements?.length && newIntervention.elements.forEach(el => (el.intervention_id = result.data.data.id));
              this.props.addIntervention(newIntervention);
            }

            this.setState({ isAddInterventionVisible: false, isAddingNewIntervention: false, isConvertToInterventionVisible: null });
          }
        })
        .catch(error => {
          console.log("Error adding intervention: ", error);
          const addInterventionsError = error.response?.data?.errors ? error.response.data.errors[0] : "";
          this.setState({ addInterventionsError, isAddingNewIntervention: false, isConvertToInterventionVisible: null });
        });
    });
  };

  handleDeleteIntervention = id => {
    this.setState({ isDeletingIntervention: true }, () => {
      Service.deleteIntervention(id)
        .then(() => {
          this.props.deleteIntervention(id);
          this.setState({ isDeletingIntervention: false });
        })
        .catch(error => {
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          this.setState({ isDeletingIntervention: false });
        });
    });
  };

  handleMediaClick = e => {
    this.setState({ isImageGalleryVisible: true, images: e.visibleImages, photoIndex: e.itemIndex });
  };

  handleChecklistAnswerUpdate = () => {
    let { countUnsavedChanges } = this.state;
    if (countUnsavedChanges > 0) {
      countUnsavedChanges--;
    }

    this.setState({ countUnsavedChanges });
  };

  handleShowCustomerModal = customerID => {
    if (!customerID) {
      return;
    }

    this.setState({ customerID });
  };

  handleAppointmentClick = id => {
    if (!id) {
      throw new Error("Appointment ID is undefined");
    }

    this.props.showAppointment(id);
  };

  handleCarClick = key => {
    if (!key) {
      throw new Error("Car key is undefined");
    }

    this.props.showCar(key);
  };

  handleCloseCustomerModal = () => {
    this.setState({ customerID: null });
  };

  getRDWDetails = () => {
    CarsService.getRDW({ reg_number: this.state.appointment.reg_number })
      .then(result => {
        this.setState({ rdwDetails: result?.data?.data || {} });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleChangeCustomerCommunication = selectedCustomerComm => {
    let { originalSelectedCustomerComm } = this.state;
    if (
      !_.isEqual(
        originalSelectedCustomerComm.sort((x, y) => {
          if (x.question_result_id < y.question_result_id) return 1;
          else if (x.question_result_id > y.question_result_id) return -1;
          else return 0;
        }),

        selectedCustomerComm.sort((x, y) => {
          if (x.question_result_id < y.question_result_id) return 1;
          else if (x.question_result_id > y.question_result_id) return -1;
          else return 0;
        })
      )
    ) {
      this.setState({ questionChanged: true });
    } else {
      this.setState({ questionChanged: false });
    }
    this.setState({ selectedCustomerComm });
  };

  handleChangeDeskCommunication = selectedDeskComm => {
    this.setState({ selectedDeskComm });
  };

  handleShowPdfPreview = () => {
    this.setState({ loadingPreview: true }, () => {
      let { selectedCustomerComm, appointment, selectedCustomerEmail, selectedCustomerPhone, selectedCustomerID, manuallyEnteredEmail, manualEmailSelected } = this.state;

      if (manualEmailSelected) {
        if (!manuallyEnteredEmail || !validateEmail(manuallyEnteredEmail)) {
          this.setState({ invalidEmail: true, loadingPreview: false });
          return;
        }
      }

      let receivers = [];
      if (selectedCustomerEmail || manualEmailSelected) {
        receivers.push({
          destination: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
          channel: CHANNELS.EMAIL,
        });
      }

      if (selectedCustomerPhone) {
        receivers.push({
          destination: selectedCustomerPhone,
          channel: CHANNELS.SMS,
        });
      }

      const customer_id = selectedCustomerID === "switch-to-manual-email" ? null : selectedCustomerID;

      if (this.props.location.third_party_send_communications && customer_id > 0) {
        receivers.push({
          destination: customer_id === appointment.customer_driver_id ? "driver" : customer_id === appointment.customer_owner_id ? "owner" : "contractor",
          channel: CHANNELS.WEBHOOK,
        });
      }

      if (selectedCustomerComm.length === 0 || receivers.length === 0) return;

      Service.getHtmlPreview({
        appointment_id: appointment.id,
        questions: selectedCustomerComm,
        receivers: receivers,
        customer_id,
        locale: localStorage.getItem("locale"),
      })
        .then(response => {
          if (response?.data?.data?.url) {
            this.setState({ pdfVisible: true }, () => {
              this.iframe.src = response.data.data.url;
            });
          }

          this.setState({ loadingPreview: false });
        })
        .catch(error => {
          this.setState({ loadingPreview: false });
          console.log("Error while loading pdf: ", error);
          error.response?.data?.errors?.length && this.msgCustomcom.show(error.response.data.errors[0], { type: "error" });
        });
    });
  };

  handleDownloadPdfPreview = () => {
    this.setState({ downloadingPDF: true }, () => {
      let { selectedCustomerComm, appointment, selectedCustomerEmail, selectedCustomerID, selectedCustomerPhone, manuallyEnteredEmail, manualEmailSelected } = this.state;

      let receivers = [];

      if (selectedCustomerEmail || manualEmailSelected) {
        receivers.push({
          destination: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
          channel: CHANNELS.EMAIL,
        });
      }

      if (selectedCustomerPhone) {
        receivers.push({
          destination: selectedCustomerPhone,
          channel: CHANNELS.SMS,
        });
      }

      const customer_id = selectedCustomerID === "switch-to-manual-email" ? null : selectedCustomerID;

      if (this.props.location.third_party_send_communications && customer_id > 0) {
        receivers.push({
          destination: customer_id === appointment.customer_driver_id ? "driver" : customer_id === appointment.customer_owner_id ? "owner" : "contractor",
          channel: CHANNELS.WEBHOOK,
        });
      }

      Service.downloadPdfPreview({
        appointment_id: appointment.id,
        questions: selectedCustomerComm,
        receivers: receivers,
        customer_id,
        locale: localStorage.getItem("locale"),
      })
        .then(response => {
          if (response?.data) saveAs(new Blob([response.data]), "Preview_" + appointment.reg_number_escaped + "_WO_" + appointment.wo_nr + "_" + getYYYMMDD() + ".pdf");

          this.setState({ downloadingPDF: false });
        })
        .catch(error => {
          this.setState({ downloadingPDF: false });
          console.log("Error while loading pdf: ", error);
        });
    });
  };

  handleDownloadPdfCarReadySent = () => {
    this.setState({ downloadingPDF: true }, () => {
      const { appointment } = this.state;

      Service.downloadPdfCarReadySent({ communication_key: appointment.customcom_receptionist_key })
        .then(response => {
          saveAs(new Blob([response.data]), "Preview_" + appointment.reg_number_escaped + "_WO_" + appointment.wo_nr + "_" + getYYYMMDD() + ".pdf");
          this.setState({ downloadingPDF: false });
        })
        .catch(error => {
          this.setState({ downloadingPDF: false });
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          console.log("Error while loading car ready sent pdf: ", error);
        });
    });
  };

  handleIframe = component => {
    this.iframe = component;
  };

  showPDFPreview = () => {
    return (
      <SemanticModal
        closeOnEscape
        size="large"
        dimmer="blurring"
        open={this.state.pdfVisible}
        onClose={() => this.setState({ pdfVisible: false })}
        closeOnDimmerClick={false}
      >
        <SemanticModal.Actions
          style={{
            textAlign: "right",
            position: "relative",
            zIndex: 1000,
          }}
        >
          <Button color="green" loading={this.state.downloadingPDF} onClick={this.handleDownloadPdfPreview}>
            {this.props.t("download").message || "DOWNLOAD"}
          </Button>
          <Button color="green" loading={this.state.isLoadingCC} onClick={this.handleSaveRepairOverview}>
            {this.props.t("send").message || "SEND"}
          </Button>
          <Button
            color="blue"
            onClick={() => {
              this.setState({ pdfVisible: false });
            }}
          >
            {this.props.t("close").message || "CLOSE"}
          </Button>
        </SemanticModal.Actions>
        <SemanticModal.Content>
          {this.renderAlertPreview()}
          <iframe title="pdf_preview" style={{ width: "100%", minHeight: "600px", border: "0px" }} ref={this.handleIframe}></iframe>
        </SemanticModal.Content>
      </SemanticModal>
    );
  };

  handleSaveRepairOverview = () => {
    let {
      selectedCustomerComm,
      appointment,
      customCommNote,
      selectedCustomerEmail,
      selectedCustomerPhone,
      selectedCustomerID,
      manuallyEnteredEmail,
      manualEmailSelected,
    } = this.state;

    this.setState({ isLoadingCC: true }, () => {
      // manually entered email is validated in Preview modal
      let receivers = [];
      if (selectedCustomerEmail || manualEmailSelected) {
        receivers.push({
          destination: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
          channel: CHANNELS.EMAIL,
        });
      }

      if (selectedCustomerPhone) {
        receivers.push({
          destination: selectedCustomerPhone,
          channel: CHANNELS.SMS,
        });
      }

      const customer_id = selectedCustomerID === "switch-to-manual-email" ? null : selectedCustomerID;

      if (this.props.location.third_party_send_communications && customer_id > 0) {
        receivers.push({
          destination: customer_id === appointment.customer_driver_id ? "driver" : customer_id === appointment.customer_owner_id ? "owner" : "contractor",
          channel: CHANNELS.WEBHOOK,
        });
      }

      if (selectedCustomerComm.length === 0 || receivers.length === 0) return;

      if (
        appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT ||
        appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED
      ) {
        Service.updatePostCustomcom({
          appointment_id: appointment.id,
          customer_id,
          questions: selectedCustomerComm,
          note: customCommNote,
          receivers,
        })
          .then(result => {
            this.setState({
              isLoadingCC: false,
              isCustomerCommunicationVisible: false,
              pdfVisible: false,
              questionChanged: false,
              receiverChanged: false,
              originalCustomerID: selectedCustomerID,
              originalCustomerEmail: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
              originalCustomerPhone: selectedCustomerPhone,
              originalCustomCommNote: customCommNote,
              originalSelectedCustomerComm: selectedCustomerComm,
            });
          })
          .catch(error => {
            this.setState({ isLoadingCC: false });
            console.error("Error updating post customer communication items.", error);
            error?.response?.data?.errors?.length && this.msgPreview.show(error.response.data.errors[0], { type: "error" });
          });
      } else {
        Service.createPostCustomcom({
          appointment_id: appointment.id,
          customer_id,
          questions: selectedCustomerComm,
          note: customCommNote,
          receivers,
        })
          .then(result => {
            const updatedAppointment = { ...this.state.appointment, customcom_receptionist_key: result?.data?.data?.receptionist_key || null };

            this.setState(
              {
                appointment: updatedAppointment,
                isLoadingCC: false,
                isCustomerCommunicationVisible: false,
                pdfVisible: false,
                questionChanged: false,
                receiverChanged: false,
                originalCustomerID: selectedCustomerID,
                originalCustomerEmail: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
                originalCustomerPhone: selectedCustomerPhone,
                originalCustomCommNote: customCommNote,
                originalSelectedCustomerComm: selectedCustomerComm,
              },
              () => this.props.onHandleUpdateAppointments(updatedAppointment)
            );
          })
          .catch(error => {
            this.setState({ isLoadingCC: false });
            console.error("Error adding post customer communication items.", error);
            error?.response?.data?.errors?.length && this.msgPreview.show(error.response.data.errors[0], { type: "error" });
          });
      }
    });
  };

  handleSaveCustomerCommunication = () => {
    let {
      selectedCustomerComm,
      appointment,
      customCommNote,
      selectedCustomerEmail,
      selectedCustomerPhone,
      selectedCustomerID,
      manuallyEnteredEmail,
      manualEmailSelected,
    } = this.state;

    if (manualEmailSelected) {
      if (!manuallyEnteredEmail || !validateEmail(manuallyEnteredEmail)) {
        this.setState({ invalidEmail: true });
        return;
      }
    }

    let receivers = [];
    if (selectedCustomerEmail || manualEmailSelected) {
      receivers.push({
        destination: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
        channel: CHANNELS.EMAIL,
      });
    }

    if (selectedCustomerPhone) {
      receivers.push({
        destination: selectedCustomerPhone,
        channel: CHANNELS.SMS,
      });
    }

    const customer_id = selectedCustomerID === "switch-to-manual-email" ? null : selectedCustomerID;

    if (this.props.location.third_party_send_communications && customer_id > 0) {
      receivers.push({
        destination: customer_id === appointment.customer_driver_id ? "driver" : customer_id === appointment.customer_owner_id ? "owner" : "contractor",
        channel: CHANNELS.WEBHOOK,
      });
    }

    if (selectedCustomerComm.length === 0 || receivers.length === 0) return;

    this.setState({ isLoadingCC: true }, () => {
      if (appointment.customcom_status > COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED) {
        this.setState({ isLoadingCC: false });
        return this.msgCustomcom.show(this.props.t("repair_overview_can_be_sent_once").message || "Only repair overview can be sent once the car is marked as ready", {
          type: "error",
        });
      }

      if (appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT) {
        Service.updateCustomcom({
          questions: selectedCustomerComm,
          appointment_id: appointment.id,
          note: customCommNote,
          receivers,
          customer_id,
        })
          .then(result => {
            this.setState({
              isLoadingCC: false,
              isCustomerCommunicationVisible: false,
              pdfVisible: false,
              questionChanged: false,
              noteChanged: false,
              receiverChanged: false,
              originalCustomerID: selectedCustomerID,
              originalCustomerEmail: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
              originalCustomerPhone: selectedCustomerPhone,
              originalCustomCommNote: customCommNote,
              originalSelectedCustomerComm: selectedCustomerComm,
            });
          })
          .catch(error => {
            this.setState({ isLoadingCC: false });
            console.error("Error updating customer communication items.", error);
            error?.response?.data?.errors?.length && this.msgCustomcom.show(error.response.data.errors[0], { type: "error" });
          });
      } else {
        Service.createCustomcom({
          questions: selectedCustomerComm,
          appointment_id: appointment.id,
          note: customCommNote,
          receivers,
          customer_id,
        })
          .then(result => {
            const updatedAppointment = { ...this.state.appointment, customcom_receptionist_key: result?.data?.data?.receptionist_key || null };

            this.setState({
              appointment: updatedAppointment,
              isLoadingCC: false,
              isCustomerCommunicationVisible: false,
              pdfVisible: false,
              questionChanged: false,
              noteChanged: false,
              receiverChanged: false,
              originalCustomerID: selectedCustomerID,
              originalCustomerEmail: manualEmailSelected ? manuallyEnteredEmail : selectedCustomerEmail,
              originalCustomerPhone: selectedCustomerPhone,
              originalCustomCommNote: customCommNote,
              originalSelectedCustomerComm: selectedCustomerComm,
            });
          })
          .catch(error => {
            this.setState({ isLoadingCC: false });
            console.error("Error adding customer communication items.", error);
            error?.response?.data?.errors && this.msgCustomcom.show(error.response.data.errors[0], { type: "error" });
          });
      }

      this.getEmailOptions();
      this.getPhoneOptions();
    });
  };

  handleOpenRegisterTabletModal = () => {
    this.setState({ registerTabletModalOpen: true });
  };

  handleCustomerCommunication = () => {
    let { isCustomerCommunicationVisible } = this.state;

    this.setState({ isCustomerCommunicationVisible: !isCustomerCommunicationVisible });
  };

  handleDiscardCustomerCommunication = () => {
    let { isCustomerCommunicationVisible } = this.state;
    this.setState({
      customCommNote: this.props.appointment.communication_note,
      isCustomerCommunicationVisible: !isCustomerCommunicationVisible,
    });
  };

  handleToggleOpenCancelAppointmentConfirm = () => {
    this.setState(prevState => ({
      cancelAppointmentError: "",
      isOpenConfirmAppointmentCancel: !prevState.isOpenConfirmAppointmentCancel,
    }));
  };

  handleCancelAppointment = reason => {
    this.setState({ isCancelingAppointment: true }, () => {
      Service.cancelAppointment({ appointment_id: this.state.appointment.id, ...reason })
        .then(() => {
          const appointment = { ...this.state.appointment, appointment_status_identifier: APPOINTMENT_STATUSES.CANCELED };
          this.setState({ appointment, isCancelingAppointment: false, isOpenConfirmAppointmentCancel: false });
        })
        .catch(error => {
          this.setState({
            cancelAppointmentError: error.response.data && error.response.data.errors.length ? error.response.data.errors[0] : "Error occurred",
            isCancelingAppointment: false,
          });
          console.error("Error deleting the appointment.", error);
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
        });
    });
  };

  toggleKioskLabelModal = () => {
    this.setState(prevState => ({ kioskLabelModalOpened: !prevState.kioskLabelModalOpened, detachLabelError: "" }));
  };

  handleConfirmLabelDetach = async () => {
    const { appointment } = this.state;

    this.setState({ isDetachingLabel: true }, async () => {
      try {
        await Service.kioskLabelDetach({ appointment_id: appointment.id });
        this.toggleKioskLabelModal();
        this.setState({ isDetachingLabel: false });
      } catch (error) {
        this.setState({
          detachLabelError: error.response.data && error.response.data.errors.length ? error.response.data.errors[0] : "Error occurred",
          isDetachingLabel: false,
        });
        console.error("Error detaching the kiosk label.", error);
        error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
      }
    });
  };

  renderConfirmDetachLabelModal = () => {
    const { t } = this.props;
    const { kioskLabelModalOpened, isDetachingLabel, detachLabelError } = this.state;

    return (
      <CustomConfirm
        type="warning"
        isOpen={kioskLabelModalOpened}
        handleCancel={this.toggleKioskLabelModal}
        handleConfirm={this.handleConfirmLabelDetach}
        isLoading={isDetachingLabel}
        confirmMsg={t("confirm_detach_label_message").message || "Do you want to detach the key label from this WO?"}
        error={detachLabelError}
      />
    );
  };

  renderCarPicture = (picture, noPictureMsg) => {
    if (picture) {
      return <img src={removeGenerationParam(picture)} alt="Customer car" />;
    } else {
      return (
        <div className="AppointmentDetail__side__picture__empty">
          <Icon name="car" />
          <p>{noPictureMsg || "No picture for the car"}</p>
        </div>
      );
    }
  };

  renderOnlineUsers = () => {
    return (
      <div className="AppointmentDetail__side__online_users">
        {this.props.detailPageOnlineUsers.map(user => {
          if (user.status === 3) return null;

          return (
            <div key={user.id} className="AppointmentDetail__side__online_user">
              {user.profile_picture && <img alt="" className={user.status === 1 ? "online" : "away"} src={user.profile_picture} />}
              {!user.profile_picture && (
                <span className={user.status === 1 ? "online" : "away"}>
                  {user.first_name?.charAt(0) || ""}
                  {user.last_name?.charAt(0) || ""}
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  renderCarReadyConfirmation = () => {
    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.isCarReadyConfirmVisible}
        handleCancel={this.handleHideCarReadyConfirm}
        handleConfirm={this.handleCarReadyConfirm}
        confirmMsg={this.props.t("are_you_sure_car_ok_status").message}
        confirmText={this.props.t("confirm").message || "Confirm"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  handleHideOnlineCheckinConfirmation = () => {
    this.setState({ isSendingOnlineCheckinConfirmationVisible: false });
  };

  handleConfirmSendOnlineCheckin = () => {
    const { appointment } = this.state;

    this.setState({ isSendingOnlineCheckin: true }, () => {
      Service.updateAppointment({ id: appointment.id, time_car_app: appointment.time_car_app })
        .then(() => {
          this.setState(prevState => ({
            isSendingOnlineCheckin: false,
            showTimeCarAppSaveIcon: false,
            originalTimeCarApp: false,
            isSendingOnlineCheckinConfirmationVisible: false,
            countUnsavedChanges: prevState.countUnsavedChanges > 0 ? prevState.countUnsavedChanges - 1 : prevState.countUnsavedChanges,
          }));
        })
        .catch(error => {
          console.error("Error saving the appointment updates.", error);
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);
          this.setState({ isSendingOnlineCheckin: false });
        });
    });
  };

  renderOnlineCheckinConfirmation = () => {
    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.isSendingOnlineCheckinConfirmationVisible}
        handleCancel={this.handleHideOnlineCheckinConfirmation}
        handleConfirm={this.handleConfirmSendOnlineCheckin}
        confirmMsg={
          this.props.t("checkin_activation_warning_msg").message ||
          "Changing the appointment date will send Online check-in communication to the customer.Do you want to proceed?"
        }
        isLoading={this.state.isSendingOnlineCheckin}
        confirmText={this.props.t("confirm").message || "Confirm"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  renderUnsavedConfirmation = () => {
    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.isUnsavedConfirmVisible}
        handleCancel={() => {
          this.setState({ isUnsavedConfirmVisible: false });
        }}
        handleConfirm={this.props.isURLHandler && this.props.authState.isQrLogin ? this.props.logout : this.props.onClose}
        confirmMsg={this.props.t("confirm_unsaved_changes").message || "You have unsaved changes. Do you want to continue?"}
        confirmText={this.props.t("confirm").message || "Confirm"}
        cancelText={this.props.t("close").message || "Close"}
      />
    );
  };

  renderSpecialIndicators = (appointment, disabled) => {
    const actionIconColors = ["green", "blue", "#f2711c", "red"];
    const shopIconTexts = ["shop_status_same_day", "shop_status_to_order", "shop_status_next_day", "shop_status_back_order"];

    return (
      <>
        <Button
          disabled={(this.props.location.dms_billing_enabled && appointment.dms_billed_updated) || disabled}
          basic
          onClick={() => {
            this.handleUpdate({
              type: "special-indicator",
              subtype: "is_money",
              shouldSave: true,
              value: appointment.is_money,
            });
          }}
          size="large"
          color={appointment.is_money ? "green" : "youtube"}
        >
          <Icon name="dollar" />
        </Button>

        <Button.Group
          className={`star-color-buttons ${appointment.is_star ? (appointment.is_star_color.startsWith("#") ? "orange" : appointment.is_star_color) : ""}`}
          basic
        >
          <Button
            disabled={disabled}
            onClick={() => {
              this.handleUpdate({
                type: "star-color",
                shouldSave: true,
                value: appointment.is_star && appointment.is_star_color ? "" : "green",
              });
            }}
            size="large"
          >
            <Icon name="star" />
          </Button>
          <Dropdown
            floating
            disabled={disabled}
            className="button icon star-color-dropdown"
            value={appointment.is_star_color}
            options={actionIconColors.map((color, key) => ({
              key,
              icon: <Icon style={color.startsWith("#") ? { color } : null} color={color.startsWith("#") ? null : color} name="star" />,
              value: color,
            }))}
            onChange={(e, data) => {
              this.handleUpdate({
                type: "star-color",
                shouldSave: true,
                value: data.value,
              });
            }}
          />
        </Button.Group>

        <Button.Group
          className={`shop-color-buttons ${appointment.is_shop ? (appointment.is_shop_color.startsWith("#") ? "orange" : appointment.is_shop_color) : ""}`}
          basic
        >
          <Button
            disabled={disabled}
            onClick={() => {
              this.handleUpdate({
                type: "shop-color",
                shouldSave: true,
                value: appointment.is_shop && appointment.is_shop_color ? "" : "green",
              });
            }}
            size="large"
          >
            <Icon name="shop" />
          </Button>
          <Dropdown
            floating
            disabled={disabled}
            className="button icon shop-color-dropdown"
            value={appointment.is_shop_color}
            options={actionIconColors.map((color, key) => ({
              key,
              icon: <Icon style={color.startsWith("#") ? { color } : null} color={color.startsWith("#") ? null : color} name="shop" />,
              text: this.props.t(shopIconTexts[key]).message || shopIconTexts[key],
              value: color,
            }))}
            onChange={(e, data) =>
              this.handleUpdate({
                type: "shop-color",
                shouldSave: true,
                value: data.value,
              })
            }
          />
        </Button.Group>
      </>
    );
  };

  renderAddIntervention = () => {
    if (this.state.isAddInterventionVisible) {
      return (
        <AddAppointmentIntervention
          keyloopEnabled={this.props.keyloopEnabled}
          appointment_id={this.props.appointment.id}
          user={this.props.authState.user}
          dms_id={this.props.location.dms_id}
          onClose={this.handleHideAddIntervention}
          onSubmit={this.handleAddIntervention}
          isAddingNewIntervention={this.state.isAddingNewIntervention}
          addInterventionsError={this.state.addInterventionsError}
        />
      );
    }
  };

  renderConvertToIntervention = () => {
    const { isConvertToInterventionVisible } = this.state;

    if (!isConvertToInterventionVisible) return null;

    const { result = null, remark = null } = isConvertToInterventionVisible;

    if (!result && !remark) return null;

    let title = "",
      description = "",
      price = "";

    if (remark) {
      title = remark.title;
      description = remark.description;
    } else {
      title = result.name;
      description = result.description;
      price = result.price;
    }

    return (
      <AddAppointmentIntervention
        keyloopEnabled={this.props.keyloopEnabled}
        appointment_id={this.props.appointment.id}
        dms_id={this.props.location.dms_id}
        onClose={this.handleHideConvertToIntervention}
        onSubmit={this.handleAddIntervention}
        title={title}
        description={description}
        price={price}
        isAddingNewIntervention={this.state.isAddingNewIntervention}
        addInterventionsError={this.state.addInterventionsError}
      />
    );
  };

  renderImageGallery = () => {
    if (this.state.isImageGalleryVisible && this.state.images) {
      const { images, photoIndex } = this.state;

      return (
        <Lightbox
          mainSrc={removeGenerationParam(images[photoIndex].url)}
          nextSrc={removeGenerationParam(images[(photoIndex + 1) % images.length].url)}
          prevSrc={removeGenerationParam(images[(photoIndex + images.length - 1) % images.length].url)}
          onCloseRequest={() => this.setState({ isImageGalleryVisible: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
        />
      );
    }
  };

  getCustomerCommStatus = appointment => {
    let status = appointment.customcom_status;
    let identifier = appointment.appointment_status_identifier;

    switch (status) {
      case COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT:
        return faEyeSlash;
      case COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED:
        return faEye;
      case COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED:
        return faUserCheck;
      case COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_CLOSED:
        return this.isCarReadyOrQualityCheckDone(identifier) ||
          (identifier === APPOINTMENT_STATUSES.DIAGNOSE && appointment.status_history.some(item => item.identifier === APPOINTMENT_STATUSES.CAR_READY))
          ? faFileInvoiceDollar
          : faLock;
      case COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT:
      case COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED:
        return faFileInvoiceDollar;
      default:
        return this.isCarReadyOrQualityCheckDone(identifier) ? faFileInvoiceDollar : null;
    }
  };

  renderCustomerModal = () => {
    const { customerID } = this.state;

    if (!customerID) return null;

    return <CustomerModal customerID={customerID} onClose={this.handleCloseCustomerModal} location={this.props.location} />;
  };

  getWOWidget = () => {
    let { location, appointment } = this.props;

    IntegrationsService.getWOWidget({ location_id: location.id, appointment_id: appointment.id })
      .then(result => {
        if (result && result.data && result.data.data && result.data.data.RedirectURL) {
          this.setState({
            wo_widget: result.data.data.RedirectURL,
            button_label: result.data.data.ButtonLabel ? result.data.data.ButtonLabel : "S",
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ wo_widget: "", button_label: "S" });
      });
  };

  handleOpenSWidget = () => {
    window.open(this.state.wo_widget, "_blank");
  };

  handleOpenAppointmentNoteModal = appointmentNote => {
    this.setState({ appointmentNote });
  };

  handleCloseAppointmentNoteModal = () => {
    this.setState({ appointmentNote: null });
  };

  handleCustomCommNoteChange = (e, data) => {
    if (this.state.originalCustomCommNote === data.value) {
      this.setState({ noteChanged: false });
    } else {
      this.setState({ noteChanged: true });
    }

    let appointment = this.state.appointment;
    appointment.communication_note = data.value;

    this.setState({ customCommNote: data.value, appointment });
  };

  getBlockCommunicationSuffix = customer => (customer.block_communication ? ` - ${this.props.t("communication_blocked").message || "Communication blocked"}` : "");

  getIsInternalCommunicationSuffix = customer => (customer.is_internal ? ` - ${this.props.t("internal").message || "Internal"}` : "");

  getPhoneOptions = () => {
    const { appointment, selectedCustomerPhone, selectedCustomerID } = this.state;
    const { t } = this.props;

    let phoneOptionSelected = "";
    let phoneOptions = [];

    if (!appointment.internal) {
      if (appointment.customer_driver && appointment.customer_driver.id) {
        if (appointment.customer_driver.tel_mobile_business) {
          phoneOptions.push({
            key: 1,
            text: appointment.customer_driver.tel_mobile_business,
            value: appointment.customer_driver.tel_mobile_business + ";" + appointment.customer_driver.id,
            disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
            description:
              (t("driver_business").message || "Driver Business") +
              this.getBlockCommunicationSuffix(appointment.customer_driver) +
              this.getIsInternalCommunicationSuffix(appointment.customer_driver),
          });

          if (selectedCustomerID === appointment.customer_driver.id && selectedCustomerPhone === appointment.customer_driver.tel_mobile_business)
            phoneOptionSelected = appointment.customer_driver.tel_mobile_business + ";" + appointment.customer_driver.id;
        }

        if (appointment.customer_driver.tel_mobile_private) {
          phoneOptions.push({
            key: 2,
            text: appointment.customer_driver.tel_mobile_private,
            value: appointment.customer_driver.tel_mobile_private + ";" + appointment.customer_driver.id,
            disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
            description:
              (t("driver_private").message || "Driver Private") +
              this.getBlockCommunicationSuffix(appointment.customer_driver) +
              this.getIsInternalCommunicationSuffix(appointment.customer_driver),
          });

          if (selectedCustomerID === appointment.customer_driver.id && selectedCustomerPhone === appointment.customer_driver.tel_mobile_private)
            phoneOptionSelected = appointment.customer_driver.tel_mobile_private + ";" + appointment.customer_driver.id;
        }
      }

      if (appointment.customer_contract && appointment.customer_contract.id) {
        if (appointment.customer_contract.tel_mobile_business) {
          phoneOptions.push({
            key: 3,
            text: appointment.customer_contract.tel_mobile_business,
            value: appointment.customer_contract.tel_mobile_business + ";" + appointment.customer_contract.id,
            disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
            description:
              (t("contract_business").message || "Contract Business") +
              this.getBlockCommunicationSuffix(appointment.customer_contract) +
              this.getIsInternalCommunicationSuffix(appointment.customer_contract),
          });

          if (selectedCustomerID === appointment.customer_contract.id && selectedCustomerPhone === appointment.customer_contract.tel_mobile_business)
            phoneOptionSelected = appointment.customer_contract.tel_mobile_business + ";" + appointment.customer_contract.id;
        }

        if (appointment.customer_contract.tel_mobile_private) {
          phoneOptions.push({
            key: 4,
            text: appointment.customer_contract.tel_mobile_private,
            value: appointment.customer_contract.tel_mobile_private + ";" + appointment.customer_contract.id,
            disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
            description:
              (t("contract_private").message || "Contract Private") +
              this.getBlockCommunicationSuffix(appointment.customer_contract) +
              this.getIsInternalCommunicationSuffix(appointment.customer_contract),
          });

          if (selectedCustomerID === appointment.customer_contract.id && selectedCustomerPhone === appointment.customer_contract.tel_mobile_private)
            phoneOptionSelected = appointment.customer_contract.tel_mobile_private + ";" + appointment.customer_contract.id;
        }
      }

      if (appointment.customer_owner && appointment.customer_owner.id) {
        if (appointment.customer_owner.tel_mobile_business) {
          phoneOptions.push({
            key: 5,
            text: appointment.customer_owner.tel_mobile_business,
            value: appointment.customer_owner.tel_mobile_business + ";" + appointment.customer_owner.id,
            disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
            description:
              (t("owner_business").message || "Owner Business") +
              this.getBlockCommunicationSuffix(appointment.customer_owner) +
              this.getIsInternalCommunicationSuffix(appointment.customer_owner),
          });

          if (selectedCustomerID === appointment.customer_owner.id && selectedCustomerPhone === appointment.customer_owner.tel_mobile_business)
            phoneOptionSelected = appointment.customer_owner.tel_mobile_business + ";" + appointment.customer_owner.id;
        }

        if (appointment.customer_owner.tel_mobile_private) {
          phoneOptions.push({
            key: 6,
            text: appointment.customer_owner.tel_mobile_private,
            value: appointment.customer_owner.tel_mobile_private + ";" + appointment.customer_owner.id,
            disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
            description:
              (t("owner_private").message || "Owner Private") +
              this.getBlockCommunicationSuffix(appointment.customer_owner) +
              this.getIsInternalCommunicationSuffix(appointment.customer_owner),
          });

          if (selectedCustomerID === appointment.customer_owner.id && selectedCustomerPhone === appointment.customer_owner.tel_mobile_private)
            phoneOptionSelected = appointment.customer_owner.tel_mobile_private + ";" + appointment.customer_owner.id;
        }
      }
    }

    phoneOptions.sort((a, b) => {
      if (a.value === phoneOptionSelected) return -1;
      else if (b.value === phoneOptionSelected) return 1;

      return 0;
    });

    if (!phoneOptionSelected) phoneOptionSelected = ";0";
    phoneOptions.push({
      text: t("do_not_send_by_SMS").message || "Do not send by SMS",
      value: ";0",
    });

    this.setState({ phoneOptions, phoneOptionSelected });
  };

  getCustomerOptions = () => {
    const { appointment } = this.state;
    const { t } = this.props;
    const customerOptions = [];

    if (appointment.customer_driver?.dms_nr) {
      customerOptions.push({
        key: appointment.customer_driver.id,
        text: appointment.customer_driver.firstname + " " + appointment.customer_driver.surname,
        value: appointment.customer_driver.id,
        disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
        description:
          (t("driver").message || "Driver") +
          this.getBlockCommunicationSuffix(appointment.customer_driver) +
          this.getIsInternalCommunicationSuffix(appointment.customer_driver),
      });
    }

    if (appointment.customer_owner?.dms_nr) {
      customerOptions.push({
        key: appointment.customer_owner.id,
        text: appointment.customer_owner.firstname + " " + appointment.customer_owner.surname,
        value: appointment.customer_owner.id,
        disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
        description:
          (t("owner").message || "Owner") +
          this.getBlockCommunicationSuffix(appointment.customer_owner) +
          this.getIsInternalCommunicationSuffix(appointment.customer_owner),
      });
    }

    if (appointment.customer_contract?.dms_nr) {
      customerOptions.push({
        key: appointment.customer_contract.id,
        text: appointment.customer_contract.firstname + " " + appointment.customer_contract.surname,
        value: appointment.customer_contract.id,
        disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
        description:
          (t("contractor").message || "Contractor") +
          this.getBlockCommunicationSuffix(appointment.customer_contract) +
          this.getIsInternalCommunicationSuffix(appointment.customer_contract),
      });
    }

    customerOptions.push({
      text: t("manually_enter_email").message || "Manually enter email",
      value: "switch-to-manual-email",
    });

    return customerOptions;
  };

  getEmailOptions = () => {
    const { appointment, selectedCustomerEmail, manualEmailSelected, selectedCustomerID } = this.state;
    const { t, dealer } = this.props;

    let emailOptionSelected = "";
    let emailOptions = [];

    if (appointment.customer_driver) {
      if (appointment.customer_driver.email_business) {
        emailOptions.push({
          key: 1,
          text: appointment.customer_driver.email_business,
          value: appointment.customer_driver.email_business + ";" + appointment.customer_driver.id,
          disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
          description:
            (t("driver_business").message || "Driver Business") +
            this.getBlockCommunicationSuffix(appointment.customer_driver) +
            this.getIsInternalCommunicationSuffix(appointment.customer_driver),
        });

        if (selectedCustomerID === appointment.customer_driver.id && selectedCustomerEmail === appointment.customer_driver.email_business)
          emailOptionSelected = appointment.customer_driver.email_business + ";" + appointment.customer_driver.id;
      }

      if (appointment.customer_driver.email_private) {
        emailOptions.push({
          key: 2,
          text: appointment.customer_driver.email_private,
          value: appointment.customer_driver.email_private + ";" + appointment.customer_driver.id,
          disabled: appointment.customer_driver.block_communication || appointment.customer_driver.is_internal,
          description:
            (t("driver_private").message || "Driver Private") +
            this.getBlockCommunicationSuffix(appointment.customer_driver) +
            this.getIsInternalCommunicationSuffix(appointment.customer_driver),
        });

        if (selectedCustomerID === appointment.customer_driver.id && selectedCustomerEmail === appointment.customer_driver.email_private)
          emailOptionSelected = appointment.customer_driver.email_private + ";" + appointment.customer_driver.id;
      }
    }

    if (appointment.customer_contract) {
      if (appointment.customer_contract.email_business) {
        emailOptions.push({
          key: 3,
          text: appointment.customer_contract.email_business,
          value: appointment.customer_contract.email_business + ";" + appointment.customer_contract.id,
          disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
          description:
            (t("contract_business").message || "Contract Business") +
            this.getBlockCommunicationSuffix(appointment.customer_contract) +
            this.getIsInternalCommunicationSuffix(appointment.customer_contract),
        });

        if (selectedCustomerID === appointment.customer_contract.id && selectedCustomerEmail === appointment.customer_contract.email_business)
          emailOptionSelected = appointment.customer_contract.email_business + ";" + appointment.customer_contract.id;
      }

      if (appointment.customer_contract.email_private) {
        emailOptions.push({
          key: 4,
          text: appointment.customer_contract.email_private,
          value: appointment.customer_contract.email_private + ";" + appointment.customer_contract.id,
          disabled: appointment.customer_contract.block_communication || appointment.customer_contract.is_internal,
          description:
            (t("contract_private").message || "Contract Private") +
            this.getBlockCommunicationSuffix(appointment.customer_contract) +
            this.getIsInternalCommunicationSuffix(appointment.customer_contract),
        });

        if (selectedCustomerID === appointment.customer_contract.id && selectedCustomerEmail === appointment.customer_contract.email_private)
          emailOptionSelected = appointment.customer_contract.email_private + ";" + appointment.customer_contract.id;
      }
    }

    if (appointment.customer_owner) {
      if (appointment.customer_owner.email_business) {
        emailOptions.push({
          key: 5,
          text: appointment.customer_owner.email_business,
          value: appointment.customer_owner.email_business + ";" + appointment.customer_owner.id,
          disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
          description:
            (t("owner_business").message || "Owner Business") +
            this.getBlockCommunicationSuffix(appointment.customer_owner) +
            this.getIsInternalCommunicationSuffix(appointment.customer_owner),
        });

        if (selectedCustomerID === appointment.customer_owner.id && selectedCustomerEmail === appointment.customer_owner.email_business)
          emailOptionSelected = appointment.customer_owner.email_business + ";" + appointment.customer_owner.id;
      }

      if (appointment.customer_owner.email_private) {
        emailOptions.push({
          key: 6,
          text: appointment.customer_owner.email_private,
          value: appointment.customer_owner.email_private + ";" + appointment.customer_owner.id,
          disabled: appointment.customer_owner.block_communication || appointment.customer_owner.is_internal,
          description:
            (t("owner_private").message || "Owner Private") +
            this.getBlockCommunicationSuffix(appointment.customer_owner) +
            this.getIsInternalCommunicationSuffix(appointment.customer_owner),
        });

        if (selectedCustomerID === appointment.customer_owner.id && selectedCustomerEmail === appointment.customer_owner.email_private)
          emailOptionSelected = appointment.customer_owner.email_private + ";" + appointment.customer_owner.id;
      }
    }

    emailOptions.sort((a, b) => {
      if (a.value === emailOptionSelected) return -1;
      else if (b.value === emailOptionSelected) return 1;

      return 0;
    });

    if (dealer.sms_gateway_id > SMS_GATEWAYS.DISABLED) {
      if (!emailOptionSelected) emailOptionSelected = ";0";
      emailOptions.push({
        text: t("do_not_send_by_email").message || "Do not send by email",
        value: ";0",
      });
    }

    emailOptions.push({
      text: t("manually_enter_email").message || "Manually enter email",
      value: "switch-to-manual-email",
    });

    if (manualEmailSelected) emailOptionSelected = "switch-to-manual-email";

    this.setState({ emailOptions, emailOptionSelected });
  };

  handleSelectCustomer = (e, { value: selectedCustomerID }) => {
    if (selectedCustomerID === "switch-to-manual-email") {
      this.setState({
        manualEmailSelected: true,
        selectedCustomerID,
        receiverChanged: this.state.originalCustomerEmail !== this.state.manuallyEnteredEmail,
      });
    } else {
      this.setState({
        manualEmailSelected: false,
        selectedCustomerEmail: "",
        manuallyEnteredEmail: "",
        receiverChanged: this.state.selectedCustomerID !== selectedCustomerID,
        selectedCustomerID,
      });
    }
  };

  handleSelectEmail = (e, data) => {
    if (data.value === "switch-to-manual-email") {
      this.setState({
        manualEmailSelected: true,
        emailOptionSelected: data.value,
        selectedCustomerEmail: "",
        receiverChanged: this.state.originalCustomerEmail !== this.state.manuallyEnteredEmail,
        selectedCustomerID: null,
        selectedCustomerPhone: "",
        phoneOptionSelected: ";0",
      });

      return;
    }

    let [selectedCustomerEmail, selectedCustomerID] = data.value.split(";");
    selectedCustomerID = parseInt(selectedCustomerID, 10);

    if (this.state.selectedCustomerEmail === selectedCustomerEmail && this.state.selectedCustomerID === selectedCustomerID) return;

    let { selectedCustomerPhone, phoneOptionSelected } = this.state;
    if (selectedCustomerID > 0 && this.state.selectedCustomerID !== selectedCustomerID) {
      selectedCustomerPhone = "";
      phoneOptionSelected = ";0";
    }

    if (!selectedCustomerID && selectedCustomerPhone) selectedCustomerID = this.state.selectedCustomerID;

    this.setState({
      manualEmailSelected: false,
      receiverChanged: this.state.selectedCustomerID !== selectedCustomerID || this.state.originalCustomerEmail !== selectedCustomerEmail,
      selectedCustomerEmail,
      selectedCustomerPhone,
      selectedCustomerID,
      emailOptionSelected: `${selectedCustomerEmail};${selectedCustomerID}`,
      phoneOptionSelected,
    });
  };

  handleEnterEmail = e => {
    this.setState({
      manuallyEnteredEmail: e.target.value,
      invalidEmail: false,
      receiverChanged: this.state.originalCustomerEmail !== e.target.value,
    });
  };

  handleSelectPhone = (e, data) => {
    let [selectedCustomerPhone, selectedCustomerID] = data.value.split(";");
    selectedCustomerID = parseInt(selectedCustomerID, 10);

    if (this.state.selectedCustomerPhone === selectedCustomerPhone && this.state.selectedCustomerID === selectedCustomerID) return;

    let { selectedCustomerEmail, manualEmailSelected } = this.state;
    if (selectedCustomerID > 0 && this.state.selectedCustomerID !== selectedCustomerID) {
      selectedCustomerEmail = "";
      manualEmailSelected = false;
    }

    if (!selectedCustomerID && selectedCustomerEmail) selectedCustomerID = this.state.selectedCustomerID;

    this.setState({
      manualEmailSelected,
      receiverChanged: this.state.selectedCustomerID !== selectedCustomerID || this.state.originalCustomerPhone !== selectedCustomerPhone,
      selectedCustomerEmail,
      selectedCustomerPhone,
      selectedCustomerID,
      phoneOptionSelected: `${selectedCustomerPhone};${selectedCustomerID}`,
    });
  };

  getCustomcomTotalPrice = isRepairOverview => {
    const checks = this.state.appointmentChecks;
    const interventions = this.state.appointment.interventions;
    const selectedCustomerComm = this.state.selectedCustomerComm;

    const { include_vat, vat: vatPercentage, dms_price_enabled } = this.props.location;

    const vat = include_vat ? 1 + vatPercentage / 100 : 1;

    let totalPrice = 0;

    if (Array.isArray(checks)) {
      checks.forEach(check => {
        Array.isArray(check.question_items) &&
          check.question_items.forEach(item => {
            if (!selectedCustomerComm.some(i => i.question_result_id === item.id)) return;

            if (item.tyre_replacements?.length) {
              const r = item.tyre_replacements.find(r => r.customer_answer && (!isRepairOverview || r.mechanic_fixed));
              if (r) totalPrice += round2(r.price * vat);
            } else if (!isRepairOverview || item.mechanic_fixed) {
              totalPrice += round2(item.price * vat);
            }
          });
      });

      if (Array.isArray(interventions) && dms_price_enabled) {
        interventions
          .filter(item => item.visible_to_customer && (!isRepairOverview || item.mechanic_fixed))
          .forEach(intervention => {
            totalPrice += round2(intervention.price * vat);
          });
      }
    }

    return totalPrice.toFixed(2);
  };

  getDeskComTotalPrice = () => {
    const { appointment, appointmentChecks: checks, selectedDeskComm } = this.state;
    const { interventions } = appointment;

    const { include_vat, vat: vatPercentage, dms_price_enabled } = this.props.location;

    const vat = include_vat ? 1 + vatPercentage / 100 : 1;

    let totalPrice = 0;

    if (Array.isArray(checks)) {
      checks.forEach(check => {
        Array.isArray(check.question_items) &&
          check.question_items.forEach(item => {
            if (!selectedDeskComm?.some(i => i.question_result_id === item.id)) return;

            if (item.tyre_replacements?.length) {
              const r = item.tyre_replacements.find(r => r.customer_answer);
              if (r) totalPrice += round2(r.price * vat);
            } else {
              totalPrice += round2(item.price * vat);
            }
          });
      });

      if (Array.isArray(interventions) && dms_price_enabled) {
        interventions
          .filter(item => item.visible_to_customer)
          .forEach(intervention => {
            totalPrice += round2(intervention.price * vat);
          });
      }
    }

    return totalPrice.toFixed(2);
  };

  handleCarUpdate = car => {
    let { appointment } = this.state;
    appointment.car = car;
    this.setState({ appointment });
  };

  handleOpenCC = key => {
    let url = `${ENV.ccBaseUrl}/#${key}`;
    window.open(url, "_blank");
  };

  handleRefreshDMS = dms_id => {
    const {
      appointment: { id: appointment_id },
    } = this.state;

    this.setState({ refreshingDMS: true }, () => {
      let refreshService;

      if (dms_id === DMS.AUTOFLEX) refreshService = Service.autoflexRefresh({ appointment_id });
      else if (nextlaneEnabled(this.props.location)) refreshService = Service.nextlaneRefresh({ appointment_id });
      else refreshService = Service.keyloopRefresh({ appointment_id });

      refreshService
        .then(() => {
          this.props.getAppointment(appointment_id).then(() => {
            this.setState({ refreshingDMS: false });
          });
        })
        .catch(error => {
          console.log("Error while updating appointment: ", error);
          error.response.data && error.response.data.errors && this.error(error.response.data.errors[0]);

          this.setState({ refreshingDMS: false });
        });
    });
  };

  renderCounterTabletModal = () => {
    const { t, authState } = this.props;

    if (!this.state.registerTabletModalOpen) return null;

    const confirmMsg = [APPOINTMENT_STATUSES.CAR_READY, APPOINTMENT_STATUSES.QUALITY_CHECK].includes(this.state.appointment.appointment_status_identifier)
      ? t("display_desk_checkout_on_tablet").message || "This action will display the desk check-out on the counter tablet. Do you want to proceed?"
      : t("display_desk_checkin_on_tablet").message || "This action will display the desk check-in on the counter tablet. Do you want to proceed?";

    if (authState.user.counter_tablet_key) {
      return (
        <CustomConfirm
          type="warning"
          isOpen={this.state.registerTabletModalOpen}
          handleCancel={() => this.setState({ registerTabletModalOpen: false })}
          handleConfirm={() => this.setState({ registerTabletModalOpen: false, isDeskCommunicationVisible: true })}
          confirmMsg={confirmMsg}
          error={""}
        />
      );
    }

    return (
      <CustomConfirm
        type="warning"
        isOpen={this.state.registerTabletModalOpen}
        handleConfirm={() => this.setState({ registerTabletModalOpen: false })}
        confirmMsg={t("connect_profile_with_tablet").message || "Please connect your profile with a counter tablet"}
        error={""}
        confirmText={t("cancel").message || "Cancel"}
        hideCancelButton
      />
    );
  };

  handleCloseDeskCheckinModal = () => {
    this.setState({ deskCheckinModalOpen: false });
  };

  handleCloseDeskCheckinQuestionsModal = () => {
    this.setState({ isDeskCommunicationVisible: false });
  };

  handleCloseCarInShopPrintModal = () => this.setState({ carInShopPrintVisible: false });
  handleOpenCarInShopPrintModal = () => this.setState({ carInShopPrintVisible: true });

  handleChangeKeyLabelPrintSize = (_, data) =>
    this.setState({ keyLabelPrintSize: data.value }, () => {
      setPreference("key-label-print-size", data.value);
    });

  getPrintKeylabelPaperSize = () => {
    switch (this.state.keyLabelPrintSize) {
      default:
      case KEY_LABEL_PRINT_SIZES.A4:
        return "car_in_shop_a4_content";
      case KEY_LABEL_PRINT_SIZES.LABEL:
        return "car_in_shop_label_content";
      case KEY_LABEL_PRINT_SIZES.KIOSK_LABEL:
        return "car_in_shop_kiosk_label_content";
    }
  };

  shortenText = (text, delimiter) => {
    if (this.state.keyLabelPrintSize !== KEY_LABEL_PRINT_SIZES.KIOSK_LABEL) return text;

    if (text.length > delimiter) return `${text.slice(0, delimiter).trim()}...`;
    else return text;
  };

  updateCurrentMileage = evt => {
    if (evt.target.value < 0) return;

    this.setState({ current_mileage: evt.target.value });
  };

  renderCarInShopPrintModal = () => {
    const { t, authState } = this.props;
    const { appointment, carInShopPrintVisible, keyLabelPrintSize } = this.state;
    const customerFirstnameSurname = (
      appointment.customer_owner
        ? appointment.customer_owner.firstname + " " + appointment.customer_owner.surname
        : appointment.customer_driver
        ? appointment.customer_driver.firstname + " " + appointment.customer_driver.surname
        : appointment.customer_contract
        ? appointment.customer_contract.firstname + " " + appointment.customer_contract.surname
        : ""
    ).trim();

    return (
      <SemanticModal
        size="small"
        closeOnEscape
        dimmer="blurring"
        closeOnDimmerClick={false}
        className={`CarInShopPrintModal ${this.getPrintKeylabelPaperSize() === "car_in_shop_kiosk_label_content" ? "CarInShopPrintModalKiosk" : ""}`}
        open={carInShopPrintVisible}
        onClose={this.handleCloseCarInShopPrintModal}
      >
        <SemanticModal.Actions>
          <Button
            color="green"
            onClick={() =>
              printJS({
                printable: this.getPrintKeylabelPaperSize(),
                type: "html",
                copyStyles: true,
              })
            }
          >
            {t("print").message || "PRINT"}
          </Button>
          <Button color="green" onClick={this.handleCloseCarInShopPrintModal}>
            {t("close").message || "CLOSE"}
          </Button>

          <Dropdown
            selection
            options={[
              { text: "A4", value: KEY_LABEL_PRINT_SIZES.A4, key: KEY_LABEL_PRINT_SIZES.A4 },
              { text: this.props.t("label").message || "Label", value: KEY_LABEL_PRINT_SIZES.LABEL, key: KEY_LABEL_PRINT_SIZES.LABEL },
              { text: this.props.t("kiosk_label").message || "Kiosk Label", value: KEY_LABEL_PRINT_SIZES.KIOSK_LABEL, key: KEY_LABEL_PRINT_SIZES.KIOSK_LABEL },
            ]}
            value={keyLabelPrintSize}
            onChange={this.handleChangeKeyLabelPrintSize}
          />
        </SemanticModal.Actions>
        <SemanticModal.Content scrolling id={this.getPrintKeylabelPaperSize()}>
          <div>{appointment.car_make}</div>
          <div>{this.shortenText(appointment.car_model, 10)}</div>
          <div className="car_in_shop_print_large">{appointment.reg_number}</div>
          <div className="car_in_shop_print_large">{appointment.vin_nr}</div>
          <div className="car_in_shop_print_large car_in_shop_print_bold">{appointment.wo_nr}</div>
          <div>{`${authState.user.first_name} ${authState.user.last_name}`.trim()}</div>
          {customerFirstnameSurname && <div>{customerFirstnameSurname}</div>}
        </SemanticModal.Content>
      </SemanticModal>
    );
  };

  renderEnterCurrentMileageModal = () => {
    const { appointment, displayEnterCurrentCarMileage, current_mileage } = this.state;
    const { t } = this.props;

    return (
      <SemanticModal open={displayEnterCurrentCarMileage} closeOnDimmerClick={false} size="mini">
        <SemanticModal.Content>
          <h3>{t("enter_current_km").message || "Enter current km"}</h3>

          <Form>
            <Form.Field>
              <label>{t("current_km").message || "Current km"}</label>
              <UserInput type="number" value={current_mileage} onChange={this.updateCurrentMileage} placeholder={t("current_km").message || "Current km"} />
            </Form.Field>
          </Form>
        </SemanticModal.Content>
        <SemanticModal.Actions>
          <Button color="red" onClick={() => this.setState({ displayEnterCurrentCarMileage: false, current_mileage: 0 })} floated="left">
            <Icon name="close" /> {t("close").message}
          </Button>
          <Button
            disabled={!current_mileage}
            color="green"
            onClick={() => {
              this.handleUpdate({
                type: "step",
                shouldSave: true,
                value: APPOINTMENT_STATUSES.CAR_IN_SHOP,
                car_in_shop: !appointment.car_in_shop || appointment.car_out_of_shop ? moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
                car_out_of_shop: appointment.car_in_shop && !appointment.car_out_of_shop ? moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
              });

              this.setState({ displayEnterCurrentCarMileage: false, current_mileage: 0 });
            }}
          >
            <Icon name="checkmark" /> {t("save").message}
          </Button>
        </SemanticModal.Actions>
      </SemanticModal>
    );
  };

  getNoteDropdownOptions = () => {
    const {
      appointment: { appointment_status_identifier },
    } = this.state;
    const { t, location, isFeatureEnabled, authState } = this.props;

    return [
      {
        key: 1,
        icon: (
          <div className="icon-container square-icon info-icon">
            <FontAwesomeIcon icon={faInfo} color="#4283CA" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.INFO,
        text: t("set_info_status").message || "Set Info status",
        disabled:
          [APPOINTMENT_STATUSES.CAR_CHECK_STARTED, APPOINTMENT_STATUSES.BACK_ORDER].includes(appointment_status_identifier) ||
          !authState.user.acl["appointments-info"]?.some(({ activity }) => activity === "new-status"),
        className: isFeatureEnabled("Info Status") && location.diagnose_status_visible ? "" : "-display-none",
      },
      {
        key: 2,
        icon: (
          <div className="icon-container square-icon backorder-icon">
            <span>BO</span>
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.BACK_ORDER,
        text: t("set_back_order").message || "Set Back Order",
        disabled:
          ![
            APPOINTMENT_STATUSES.CUSTOMER_OK,
            APPOINTMENT_STATUSES.CAR_READY,
            APPOINTMENT_STATUSES.QUALITY_CHECK,
            APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW,
            APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW,
          ].includes(appointment_status_identifier) || !authState.user.acl["appointments-backorder"]?.some(({ activity }) => activity === "new-status"),
      },
      {
        key: 3,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faFileAlt} color="#4283CA" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.WO,
        text: t("add_note").message || "Add Note",
        disabled: !authState.user.acl["appointment-notes-wo"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 4,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faStickyNote} color="red" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.MAIN,
        text: t("add_main_note").message || "Add Main Note",
        disabled: !authState.user.acl["appointment-notes"]?.some(({ activity }) => activity === "create"),
      },
      {
        key: 5,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faPhoneAlt} color="#21BA45" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER,
        text: t("call_customer_note").message || "Call Customer Note",
        disabled: !authState.user.acl["appointment-notes-customer-call"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 6,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faPaperclip} color="#4283CA" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.ATTACHMENT,
        text: t("add_attachments").message || "Add Attachment(s)",
        className: isFeatureEnabled("File Attachment") ? "" : "-display-none",
        disabled: !authState.user.acl["appointment-notes-attachments"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 7,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faFileInvoiceDollar} color="#46B046" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.BILL_NOTE,
        text: t("add_customer_bill").message || "Add Customer Bill",
        disabled:
          !location.repair_overview_enabled ||
          ![
            APPOINTMENT_STATUSES.CUSTOMER_OK,
            APPOINTMENT_STATUSES.CAR_READY,
            APPOINTMENT_STATUSES.QUALITY_CHECK,
            APPOINTMENT_STATUSES.CAR_OK_PLUS_REPAIR_OVERVIEW,
            APPOINTMENT_STATUSES.QUALITY_CHECK_PLUS_REPAIR_OVERVIEW,
          ].includes(appointment_status_identifier) ||
          !authState.user.acl["appointment-notes-bills"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 8,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faSteeringWheel} color="#000000" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE,
        text: t("add_temporary_driver").message || "Add Temporary Driver",
        disabled: !authState.user.acl["appointment-notes-temp-driver"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 9,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faExclamationTriangle} color="#C83628" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.RECURRING_CAR,
        text: t("add_recurring_car").message || "Add Recurring Car",
        disabled: !authState.user.acl["appointment-notes-recurring-car"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
      {
        key: 10,
        icon: (
          <div className="icon-container">
            <FontAwesomeIcon icon={faParking} color="#4283CA" />
          </div>
        ),
        value: APPOINTMENT_NOTE_TYPES.PARKING,
        text: t("add_parking_location").message || "Add Parking Location",
        disabled: !authState.user.acl["appointment-notes-parking-location"]?.some(({ activity }) => activity === "create" || activity === "update"),
      },
    ];
  };

  handleNoteSelected = appointment_note_type_id => {
    let note = { appointment_note_type_id };

    if (
      [
        APPOINTMENT_NOTE_TYPES.MAIN,
        APPOINTMENT_NOTE_TYPES.ATTACHMENT,
        APPOINTMENT_NOTE_TYPES.CALL_CUSTOMER,
        APPOINTMENT_NOTE_TYPES.BILL_NOTE,
        APPOINTMENT_NOTE_TYPES.RECURRING_CAR,
        APPOINTMENT_NOTE_TYPES.PARKING,
      ].includes(appointment_note_type_id)
    ) {
      const foundNote = this.state.appointment.notes?.find(note => note.appointment_note_type_id === appointment_note_type_id);
      if (foundNote) note = foundNote;
    }

    this.handleOpenAppointmentNoteModal({ ...note });
  };

  getLastDmsUpdateTime = last_dms_update => {
    const { t } = this.props;

    if (!last_dms_update) return t("no_dms_update_yet").message || "Never";

    const differenceLastDmsUpdateAndNow = new Date() - new Date(last_dms_update),
      days = Math.floor(differenceLastDmsUpdateAndNow / (1000 * 60 * 60 * 24)),
      hours = Math.floor(differenceLastDmsUpdateAndNow / (1000 * 60 * 60)),
      minutes = Math.floor(differenceLastDmsUpdateAndNow / (1000 * 60)),
      seconds = Math.floor(differenceLastDmsUpdateAndNow / 1000);

    if (days >= 1) return `${days} ${days > 1 ? t("days").message || "Days" : t("day").message || "Day"}`;
    if (hours >= 1) return `${hours} ${hours > 1 ? t("hours").message || "Hours" : t("hour").message || "Hour"}`;
    if (minutes >= 1) return `${minutes} ${minutes > 1 ? t("mins").message || "Mins" : t("min").message || "Min"}`;
    if (seconds >= 1) return `${seconds} ${seconds > 1 ? t("secs").message || "Secs" : t("sec").message || "Sec"}`;

    return t("just_now").message || "Just now";
  };

  renderDeskCheckInModal = () => {
    this.setState({ deskCheckinModalOpen: true, isDeskCommunicationVisible: false });
  };

  handleCopyToClipboard = (text, successCopyTitle) => {
    copyToClipboard(text);
    this.msgSuccessCopy.show(successCopyTitle, { type: "success" });
  };

  handleChangeTimeCarApp = e => {
    e = moment(e);

    let { appointment, originalTimeCarApp, countUnsavedChanges, showTimeCarAppSaveIcon } = this.state;

    if (!originalTimeCarApp) {
      originalTimeCarApp = moment(appointment.time_car_app).hour(12).format("YYYY-MM-DD");
      countUnsavedChanges++;
    } else if (originalTimeCarApp === e.hour(12).format("YYYY-MM-DD")) {
      originalTimeCarApp = false;
      showTimeCarAppSaveIcon = false;
      countUnsavedChanges--;
    }

    this.setState({ appointment, originalTimeCarApp, countUnsavedChanges, showTimeCarAppSaveIcon }, () => {
      this.handleUpdate({
        type: "date",
        subtype: "time_car_app",
        value: e.hour(12).format(),
      });
    });
  };

  handleRestoreAppointment = () => {
    this.setState({ isRestoringAppointment: true }, () => {
      Service.restoreCanceledAppointemnt({ appointment_id: this.state.appointment.id })
        .then(() => {
          const appointment = { ...this.state.appointment, appointment_status_identifier: APPOINTMENT_STATUSES.NEW_CAR };

          this.setState({ appointment, isRestoringAppointment: false }, () => this.props.onHandleUpdateAppointments(appointment));
        })
        .catch(error => {
          console.error("Error restoring appointment", error);
          const errorMessage = error?.response?.data?.errors?.length ? error.response.data.errors[0] : "Error restoring appointment";
          this.setState({ isRestoringAppointment: false }, () => this.error(errorMessage));
        });
    });
  };

  render() {
    if (!this.state.appointment || !this.state.appointment.id) {
      return null;
    }

    let { location, dealer, onRegistrationClick, onClose, currentUser, onHandleUpdateAppointments, t } = this.props;
    let {
      appointment,
      appointmentChecks,
      isCustomerCommunicationVisible,
      emailOptions,
      emailOptionSelected,
      phoneOptions,
      phoneOptionSelected,
      selectedCustomerComm,
      statusHistory,
      isEditable,
      selectedCustomerID,
      appointmentNote,
      isUpdatingAppointmentStatus,
      isRestoringAppointment,
    } = this.state;

    const isPriceEditable = this.isPriceEditable(currentUser);

    let DASH_CONFIG = location ? location : {};

    let appNextDate = formatDateIfValid(appointment.next_date);
    // let carReturnDate = formatDateIfValid(appointment.car_return_time);

    let dms_registration = moment(appointment.car.dms_registration).format("DD-MM-YYYY");
    if (!moment(appointment.car.dms_registration).isValid()) {
      dms_registration = t("no_data").message || "No data";
    }

    let rdw_first_registration = moment(appointment.car.rdw_first_registration).format("DD-MM-YYYY");
    if (!moment(appointment.car.rdw_first_registration).isValid()) {
      rdw_first_registration = t("no_data").message || "No data";
    }

    let rdw_first_registration_nl = moment(appointment.car.rdw_first_registration_nl).format("DD-MM-YYYY");
    if (!moment(appointment.car.rdw_first_registration_nl).isValid()) {
      rdw_first_registration_nl = t("no_data").message || "No data";
    }

    const customerOptions = this.getCustomerOptions();
    let saveButtonEnabled = false;

    if (this.state.questionChanged) saveButtonEnabled = true;

    if (appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT && this.state.receiverChanged) saveButtonEnabled = true;

    if (appointment.customcom_status >= COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_SENT && this.state.noteChanged) saveButtonEnabled = true;

    if (this.state.countUnsavedChanges > 0) saveButtonEnabled = false;

    if (this.state.manualEmailSelected && !this.state.manuallyEnteredEmail) saveButtonEnabled = false;

    if (!this.state.manualEmailSelected && !this.state.selectedCustomerEmail && !this.state.selectedCustomerPhone && !location.third_party_send_communications)
      saveButtonEnabled = false;

    if (!location.third_party_send_communications && dealer.sms_gateway_id > SMS_GATEWAYS.DISABLED && emailOptionSelected === ";0" && phoneOptionSelected === ";0")
      saveButtonEnabled = false;

    let saveButtonDisabled = !saveButtonEnabled;

    const isCarReadyOrQualityCheckDone = this.isCarReadyOrQualityCheckDone(appointment.appointment_status_identifier);

    const isManufacturer = currentUser.role_id === ROLES.MANUFACTURER;
    const isMechanic = [ROLES.MECHANIC].includes(currentUser.role_id);

    const preferred_communication_channel =
      appointment.customer_driver?.preferred_communication_channel ||
      appointment.customer_owner?.preferred_communication_channel ||
      appointment.customer_contract?.preferred_communication_channel ||
      CHANNELS.EMAIL;

    const renderCustomerCommunication = () => {
      const { t } = this.props;
      const { isLoadingCC, isCustomerCommunicationVisible, customCommNote, manuallyEnteredEmail, invalidEmail, manualEmailSelected, appointment } = this.state;

      const billNoteCustomer = appointment.notes?.find(note => note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.BILL_NOTE);

      return (
        <SemanticModal
          size="fullscreen"
          dimmer="blurring"
          open={isCustomerCommunicationVisible}
          closeOnDimmerClick={false}
          closeOnEscape
          onClose={this.handleCustomerCommunication}
        >
          <SemanticModal.Content scrolling>
            {this.renderAlertCustomcom()}
            <div className="AppointmentDetail__content__inner">
              <Grid>
                {location.third_party_send_communications && (
                  <Grid.Row columns={2}>
                    <Grid.Column width={3} className="-no-padding-left">
                      <b>{this.props.t("select_customer").message || "Select customer"}</b>
                    </Grid.Column>
                    <Grid.Column width={13} className="-no-padding-right">
                      <Dropdown
                        placeholder={this.props.t("select_customer").message || "Select customer"}
                        fluid
                        selection
                        options={customerOptions}
                        value={selectedCustomerID}
                        onChange={this.handleSelectCustomer}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                {!location.third_party_send_communications && (
                  <Grid.Row columns={2}>
                    <Grid.Column width={3} className="-no-padding-left">
                      <div className="preferred-communication-chanel-container">
                        <b>{this.props.t("select_email").message || "Select email"}</b>
                        {preferred_communication_channel === CHANNELS.EMAIL && (
                          <div className="preferred-communication-channel-label">
                            <Icon name="star" />
                          </div>
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={13} className="-no-padding-right">
                      <Dropdown
                        placeholder={this.props.t("select_email").message || "Select email"}
                        fluid
                        selection
                        options={emailOptions}
                        value={emailOptionSelected}
                        onChange={this.handleSelectEmail}
                      />
                      <br />
                    </Grid.Column>
                  </Grid.Row>
                )}

                {manualEmailSelected && (
                  <Grid.Row>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={13} style={{ paddingRight: "0px" }}>
                      <UserInput
                        style={{ display: "flex" }}
                        type="text"
                        placeholder={this.props.t("email").message || "Email"}
                        value={manuallyEnteredEmail}
                        onChange={this.handleEnterEmail}
                      />
                      {invalidEmail && <span style={{ color: "red" }}>{this.props.t("invalid_email").message || "Please enter a valid email address"}</span>}
                    </Grid.Column>
                  </Grid.Row>
                )}

                {!location.third_party_send_communications && dealer.sms_gateway_id > SMS_GATEWAYS.DISABLED && (
                  <Grid.Row columns={2}>
                    <Grid.Column width={3} className="-no-padding-left">
                      <div className="preferred-communication-chanel-container">
                        <b>{this.props.t("select_phone").message || "Select phone number"}</b>
                        {preferred_communication_channel === CHANNELS.SMS && (
                          <div className="preferred-communication-channel-label">
                            <Icon name="star" />
                          </div>
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={13} className="-no-padding-right">
                      <Dropdown
                        placeholder={this.props.t("select_phone").message || "Select phone number"}
                        fluid
                        selection
                        options={phoneOptions}
                        value={phoneOptionSelected}
                        onChange={this.handleSelectPhone}
                        disabled={this.state.manualEmailSelected}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
              <UserInput placeholder={this.props.t("note").message || "Note"} fluid onChange={this.handleCustomCommNoteChange} value={customCommNote} />
              {billNoteCustomer && (
                <div className="bill-note-container">
                  <div>
                    <Label className="-margin-right-5">
                      <FontAwesomeIcon icon={faFileInvoiceDollar} color="#46B046" />
                    </Label>
                    <FileDropDown
                      disableUpload
                      showPreview
                      showIcon
                      files={billNoteCustomer.attachments}
                      trigger={
                        <Label>
                          <Icon name="attach" color="blue" style={{ marginRight: "0px" }} /> <span>{billNoteCustomer.attachments.length}</span>
                        </Label>
                      }
                    />
                    <span className="-margin-left-10">{billNoteCustomer.note}</span>
                  </div>

                  <div className="user-details">
                    {billNoteCustomer.updated_by
                      ? `${t("updated_by").message || "Updated by"} ${billNoteCustomer.updated_by.first_name} ${billNoteCustomer.updated_by.last_name} ${
                          t("at").message || "at"
                        } ${moment(billNoteCustomer.updated_on).format("DD-MM-YYYY HH:mm")}`
                      : `${t("Added_by").message || "Added by"} ${billNoteCustomer.user.first_name} ${billNoteCustomer.user.last_name} ${
                          t("at").message || "at"
                        } ${moment(billNoteCustomer.created_on).format("DD-MM-YYYY HH:mm")}`}
                  </div>
                </div>
              )}

              <br />
              <AppointmentLists
                dms_price_enabled={location.dms_price_enabled}
                onInterventionDelete={this.handleDeleteIntervention}
                isDeletingIntervention={this.state.isDeletingIntervention}
                appointment={appointment}
                dashboardConfig={DASH_CONFIG}
                interventions={appointment.interventions}
                checks={appointmentChecks}
                onInterventionAnswerUpdate={this.handleInterventionAnswerUpdate}
                onChecklistAnswerUpdate={this.handleChecklistAnswerUpdate}
                onMediaClick={this.handleMediaClick}
                onShowAddInterventionClick={this.handleShowAddIntervention}
                isEditable={isEditable}
                isPriceEditable={isPriceEditable}
                currentUser={this.props.currentUser}
                openCarDetail={onRegistrationClick}
                isCustomerCommunicationVisible={isCustomerCommunicationVisible}
                onChangeCustomerCommunication={this.handleChangeCustomerCommunication}
                selectedCustomerComm={selectedCustomerComm}
                location={this.props.location}
              />
              <div className="TotalItems">
                {isCarReadyOrQualityCheckDone ? (
                  <h5>
                    {t("total_fixed_items").message || "Total amount of items fixed by mechanic"}: {this.getCustomcomTotalPrice(true)} €
                  </h5>
                ) : (
                  <h5>
                    {t("total_selected_items").message || "Total amount of selected items"}: {this.getCustomcomTotalPrice(false)} €
                  </h5>
                )}
              </div>
            </div>
          </SemanticModal.Content>
          <SemanticModal.Actions>
            <Button color="red" onClick={this.handleDiscardCustomerCommunication} floated="left">
              <Icon name="close" /> {this.props.t("close").message || "CLOSE"}
            </Button>

            {isCarReadyOrQualityCheckDone ? (
              <Button loading={this.state.loadingPreview} disabled={this.state.loadingPreview || saveButtonDisabled} color="green" onClick={this.handleShowPdfPreview}>
                <Icon name="checkmark" /> {this.props.t("preview").message || "Preview"}
              </Button>
            ) : (
              <Button loading={isLoadingCC} disabled={isLoadingCC || saveButtonDisabled} color="green" onClick={this.handleSaveCustomerCommunication}>
                <Icon name="checkmark" /> {this.props.t("send").message || "Send"}
              </Button>
            )}
          </SemanticModal.Actions>
        </SemanticModal>
      );
    };

    let rdw_enabled = this.props.isFeatureEnabled("RDW");
    if (rdw_enabled) {
      if (location.country && location.country !== "NL") {
        rdw_enabled = false;
      }
    }

    let deskCheckinDisabled = appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CANCELED;

    return (
      <SemanticModal className="AppointmentDetailModal" size="fullscreen" open={true}>
        {this.showPDFPreview()}

        <AppointmentNote note={appointmentNote} onClose={this.handleCloseAppointmentNoteModal} appointment={this.props.appointment} />

        <div className="AppointmentDetail">
          <div className="AppointmentDetail__side">
            {this.renderOnlineUsers()}

            <div className="AppointmentDetail__side__picture">{this.renderCarPicture(appointment.car.profile_picture, t("no_car_picture").message)}</div>

            <div className="AppointmentDetail__side__car">
              {appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CANCELED && !isManufacturer && (
                <>
                  {this.props.location.service_box_visible_on_wo && this.state.wo_widget && (
                    <Button fluid basic size="large" className="SButton" onClick={this.handleOpenSWidget}>
                      {this.state.button_label}
                    </Button>
                  )}

                  {this.props.location.mcc_button_visible && <MCCButton appointment={appointment} />}

                  {this.props.location.dbb_enabled && this.props.location.dbb_user && (
                    <DBBButton appointment={appointment} onCarUpdate={this.handleCarUpdate} onError={err => this.error(err)} />
                  )}

                  {rdw_enabled && <RDWDetail detail={this.state.rdwDetails} getRDWDetails={this.getRDWDetails} />}

                  {this.props.location.is_robnet_enabled && (
                    <Can I="get-contract" the="robnet">
                      <ROBDetail car={appointment.car} />
                    </Can>
                  )}
                </>
              )}

              {isAdmin(currentUser.role_id) && (
                <Can I="read_log" the="appointments">
                  <AppointmentDetailLog appointmentId={appointment.id} />
                </Can>
              )}
            </div>

            <ul className="AppointmentDetail__side__list">
              {this.props.globalState.selectedLocation.id === "all" && this.props.currentUser.location_column_visible && (
                <li>
                  <span className="-detail-type">{t("location").message || "Location"}</span>
                  <span className="-detail-value">{this.props.location.name}</span>
                </li>
              )}
              {!appointment.dms_nr.startsWith("claire_") && (
                <li>
                  <Grid container columns={2}>
                    <Grid.Column width={[DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS, DMS.AUTOFLEX].includes(location.dms_id) ? 13 : 16} className="-no-padding">
                      <span className="-detail-type">{t("last_dms_update").message || "Last DMS update"}</span>
                      <span className="-detail-value">{this.getLastDmsUpdateTime(appointment.last_dms_update)}</span>
                    </Grid.Column>
                    {[DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS, DMS.AUTOFLEX].includes(location.dms_id) && appointment.status_override !== STATUS_OVERRIDE.Canceled && (
                      <Grid.Column width={3} className="-no-padding" textAlign="right">
                        <Button
                          circular
                          icon="refresh"
                          loading={this.state.refreshingDMS}
                          disabled={this.state.refreshingDMS}
                          onClick={() => this.handleRefreshDMS(location.dms_id)}
                        />
                      </Grid.Column>
                    )}
                  </Grid>
                </li>
              )}
              <li>
                <span className="-detail-type">{t("WO Nr").message || "WO"}</span>
                <span className="-detail-value">
                  {appointment.wo_nr && (
                    <Icon
                      name="copy outline"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleCopyToClipboard(appointment.wo_nr, t("wo_nr_successfully_copied").message || "WO number successfully copied")}
                    />
                  )}

                  <AppointmentPin appointment={appointment} onHandleUpdateAppointments={onHandleUpdateAppointments} />
                  {appointment.internal && <FontAwesomeIcon className="internal-icon" icon={faUserSlash} />}
                  {appointment.wo_nr}
                </span>
              </li>
              <li>
                <span className="-detail-type">{t("current_km").message}</span>
                <span className="-detail-value">
                  <InlineInputIcon
                    disabled={!isEditable}
                    type="number"
                    placeholder={t("current_km").message}
                    value={appointment.current_km}
                    icon="save"
                    leftIcon={null}
                    invalidNumberValue={value => value > appointment.next_km && appointment.next_km}
                    onSave={value => {
                      if (isNaN(value)) value = 0;

                      this.handleUpdate({
                        shouldSave: true,
                        type: "current_km",
                        value: parseInt(value, 10),
                      });
                    }}
                    config={{
                      style: {
                        width: "8em",
                      },
                      name: "car-current-km",
                      fixedDecimalScale: false,
                    }}
                  />
                </span>
              </li>
              {!!this.props.globalState.selectedLocation.dms_capability_ids?.includes(DMS_CAPABILITIES.MILEAGE) && (
                <li>
                  <span className="-detail-type">{t("dms_km").message || "DMS Km"}</span>
                  <span className="-detail-value" style={{ fontWeight: "300" }}>
                    {appointment.dms_km}
                  </span>
                </li>
              )}

              <li>
                <span className="-detail-type">{t("Reg Nr").message || "Reg. Nr."}</span>
                <span className="-detail-value -reg-nr">
                  {appointment.reg_number && (
                    <Icon
                      name="copy outline"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.handleCopyToClipboard(appointment.reg_number, t("reg_nr_successfully_copied").message || "Registration number successfully copied")
                      }
                    />
                  )}

                  <span
                    className="reg-nr-clickable"
                    onClick={() => {
                      if (!isMechanic) onRegistrationClick(appointment.car_id, appointment.wo_nr);
                    }}
                  >
                    {appointment.is_lease_company ? <Icon name="building" /> : <Icon name="info circle" />}

                    {appointment.reg_number}
                  </span>
                </span>
              </li>
              <li>
                <span className="-detail-type">{t("car_make").message}</span>
                <span className="-detail-value">{appointment.car_make}</span>
              </li>
              <li>
                <span className="-detail-type">{t("car_model").message}</span>
                <span className="-detail-value">{appointment.car_model}</span>
              </li>
              {DASH_CONFIG.apk_visible && appointment.car_apk_date && (
                <li>
                  <span className="-detail-type">{t("apk_date").message || "APK Date"}</span>
                  <span className="-detail-value">
                    <CarInspectionDate date={appointment.car_apk_date} />
                  </span>
                </li>
              )}
              {DASH_CONFIG.hu_visible && appointment.car_hu_date && (
                <li>
                  <span className="-detail-type">{t("hu_date").message || "Hu Date"}</span>
                  <span className="-detail-value">
                    <CarInspectionDate date={appointment.car_hu_date} />
                  </span>
                </li>
              )}
              <li>
                <span className="-detail-type">{t("vin_number").message || "Vin/Chassis number"}</span>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  {appointment.vin_nr && (
                    <Icon
                      name="copy outline"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleCopyToClipboard(appointment.vin_nr, t("vin_nr_successfully_copied").message || "VIN number successfully copied")}
                    />
                  )}

                  <span className="-detail-value">{appointment.vin_nr}</span>
                </div>
              </li>
              <li>
                <span className="-detail-type">{t("dms_registration").message || "DMS Registration"}</span>
                <span className="-detail-value">{dms_registration}</span>
              </li>
              {rdw_enabled && (
                <>
                  <li>
                    <span className="-detail-type">{t("rdw_first_registration").message || "RDW first registration"}</span>
                    <span className="-detail-value">{rdw_first_registration}</span>
                  </li>
                  <li>
                    <span className="-detail-type">{t("rdw_first_registration_nl").message || "RDW first registration NL"}</span>
                    <span className="-detail-value">{rdw_first_registration_nl}</span>
                  </li>
                </>
              )}
              <li>
                <span className="-detail-type">{t("engine_nr").message || "Engine number"}</span>
                <span className="-detail-value">{appointment.car.engine_number ? appointment.car.engine_number : t("no_data").message || "No data"}</span>
              </li>
              {appointment.due_in && (
                <li>
                  <span className="-detail-type">{t("scheduled_in").message || "Scheduled in"}</span>
                  <span className="-detail-value">{moment(appointment.due_in).format("DD-MM-YYYY HH:mm")}</span>
                </li>
              )}
              <li>
                <span className="-detail-type"> {t("scheduled_out").message || "Scheduled out"}</span>
                {/*    { appointment.is_local ?*/}
                {/*    <span className="InlineInput -detail-value">*/}
                {/*    <div className="InlineInput__valueinput">*/}
                {/*        <DatePicker*/}
                {/*            placeholderText={t('no_data').message || "No data"}*/}
                {/*            locale="en-gb"*/}
                {/*            dateFormat="DD-MM-YYYY"*/}
                {/*            todayButton={t('today').message || 'Today'}*/}
                {/*            selected={carReturnDate && moment(carReturnDate, 'DD-MM-YYYY')}*/}
                {/*            onChange={(e) => {*/}
                {/*              let { originalCarReturnTime, countUnsavedChanges, showCarReadyTimeSaveIcon } = this.state;*/}
                {/*              if (!originalCarReturnTime) {*/}
                {/*                originalCarReturnTime = moment(appointment.car_return_time).hour(12).format("YYYY-MM-DD");*/}
                {/*                countUnsavedChanges++;*/}
                {/*              } else if(originalCarReturnTime === e.hour(12).format('YYYY-MM-DD')) {*/}
                {/*                originalCarReturnTime = false;*/}
                {/*                showCarReadyTimeSaveIcon = false;*/}
                {/*                countUnsavedChanges--;*/}
                {/*              }*/}

                {/*              this.setState({ originalCarReturnTime, countUnsavedChanges, showCarReadyTimeSaveIcon }, () => {*/}
                {/*                this.handleUpdate({*/}
                {/*                  type: 'date',*/}
                {/*                  subtype: 'car_return_time',*/}
                {/*                  value: e.hour(12).format()*/}
                {/*                });*/}
                {/*              });*/}
                {/*            }}*/}
                {/*            dropdownMode={"select"}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    {this.state.showCarReadyTimeSaveIcon &&*/}
                {/*      <Button type="submit" onClick={() => this.handleUpdate({shouldSave: true, type: 'car_return_time'})}><Icon name='save'/></Button>*/}
                {/*    }*/}
                {/*</span>*/}
                {/*: <span className="-detail-value">{  appointment.car_return_time ? moment(appointment.car_return_time).format('DD-MM-YYYY'): t("no_data").message || "No data"} </span>*/}
                {/*}*/}
                <span className="-detail-value">
                  {appointment.car_return_time ? moment(appointment.car_return_time).format("DD-MM-YYYY HH:mm") : t("no_data").message || "No data"}{" "}
                </span>
              </li>
              {DASH_CONFIG.date_visible && (
                <li className="appointment-date-section">
                  <span className="-detail-type -margin-bottom-10">{t("app_date").message || "Appointment Date"}</span>
                  <span className="InlineInput -detail-value">
                    <div className="InlineInput__valueinput">
                      <AppointmentDate
                        appointment={appointment}
                        displayPropagationDays
                        canChangeAppointmentDate={appointment.dms_nr?.startsWith("claire_") && isEditable}
                        onChangeDate={this.handleChangeTimeCarApp}
                      />

                      {this.state.showTimeCarAppSaveIcon && (
                        <Button type="submit" onClick={() => this.handleUpdate({ shouldSave: true, type: "time_car_app" })}>
                          <Icon name="save" />
                        </Button>
                      )}
                    </div>
                  </span>
                </li>
              )}
              {!this.props.location.hide_next_km_and_next_date && (
                <li>
                  <span className="-detail-type">{t("next_km").message}</span>
                  <span className="-detail-value">
                    <InlineInputIcon
                      disabled={!isEditable}
                      type="number"
                      placeholder={t("next_km").message}
                      value={appointment.next_km}
                      icon="save"
                      leftIcon={null}
                      invalidNumberValue={value => value < appointment.current_km}
                      onSave={value => {
                        if (isNaN(value)) value = 0;

                        this.handleUpdate({
                          shouldSave: true,
                          type: "next_km",
                          value: parseInt(value, 10),
                        });
                      }}
                      config={{
                        style: {
                          width: "8em",
                        },
                        name: "car-next-km",
                        fixedDecimalScale: false,
                      }}
                    />
                  </span>
                </li>
              )}

              {!this.props.location.hide_next_km_and_next_date && (
                <li>
                  <span className="-detail-type">{t("next_date").message || "Next date"}</span>
                  <span className="InlineInput -detail-value">
                    <div className="InlineInput__valueinput">
                      <DatePicker
                        disabled={!isEditable}
                        dateFormat="dd-MM-yyyy"
                        todayButton={t("today").message || "Today"}
                        selected={appNextDate && moment(appNextDate, "DD-MM-YYYY").toDate()}
                        onChange={e => {
                          e = moment(e);

                          let { originalNextDate, countUnsavedChanges, showNextDateSaveIcon } = this.state;
                          if (!originalNextDate) {
                            originalNextDate = moment(appointment.next_date).hour(12).format("YYYY-MM-DD");
                            countUnsavedChanges++;
                          } else if (originalNextDate === e.hour(12).format("YYYY-MM-DD")) {
                            originalNextDate = false;
                            showNextDateSaveIcon = false;
                            countUnsavedChanges--;
                          }

                          this.setState({ originalNextDate, countUnsavedChanges, showNextDateSaveIcon }, () => {
                            this.handleUpdate({
                              type: "date",
                              subtype: "next_date",
                              value: e.hour(12).format(),
                            });
                          });
                        }}
                        dropdownMode={"select"}
                        onChangeRaw={e => e.preventDefault()}
                        showMonthDropdown
                        showYearDropdown
                        withPortal
                      />
                    </div>
                    {this.state.showNextDateSaveIcon && (
                      <Button type="submit" onClick={() => this.handleUpdate({ shouldSave: true, type: "next_date" })}>
                        <Icon name="save" />
                      </Button>
                    )}
                  </span>
                </li>
              )}

              {DASH_CONFIG.dp_driver_visible &&
                appointment.customer_driver &&
                getDriverItem({
                  driverID: isManufacturer ? 0 : appointment.customer_driver_id,
                  driver: appointment.customer_driver,
                  onShowCustomerDetail: this.handleShowCustomerModal,
                  typeText: t("driver_name").message || "Driver",
                  type: "driver",
                  isMechanic,
                })}
              {DASH_CONFIG.dp_contractor_visible &&
                appointment.customer_contract &&
                getDriverItem({
                  driverID: isManufacturer ? 0 : appointment.customer_contract_id,
                  driver: appointment.customer_contract,
                  onShowCustomerDetail: this.handleShowCustomerModal,
                  typeText: t("contractor_name").message || "Contractor",
                  type: "contractor",
                  isMechanic,
                })}
              {DASH_CONFIG.dp_owner_visible &&
                appointment.customer_owner &&
                getDriverItem({
                  driverID: isManufacturer ? 0 : appointment.customer_owner_id,
                  driver: appointment.customer_owner,
                  onShowCustomerDetail: this.handleShowCustomerModal,
                  typeText: t("owner_name").message || "Owner",
                  type: "owner",
                  isMechanic,
                })}
            </ul>
          </div>

          <div className="AppointmentDetail__content" id="appointmentDetailContentScrollContainer">
            <div className="AppointmentDetail__content__topbar">
              {!isManufacturer && !isMechanic && (
                <>
                  {appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CANCELED ? (
                    <div className="AppointmentDetail__content__topbar__steps">
                      <AppointmentSteps
                        onStepClick={this.handleUpdate}
                        appointment={appointment}
                        dashConfig={DASH_CONFIG}
                        commEnabled={this.props.location.diagnose_overview_enabled}
                        isUpdatingAppointmentStatus={isUpdatingAppointmentStatus}
                        location={this.props.location}
                      />
                    </div>
                  ) : (
                    <AppointmentRestoreHandle onRestoreAppointment={this.handleRestoreAppointment} isRestoringAppointment={isRestoringAppointment} />
                  )}
                </>
              )}

              <div className="AppointmentDetail__content__topbar__actions">
                {appointment.appointment_status_identifier !== APPOINTMENT_STATUSES.CANCELED && (
                  <>
                    {appointment.kiosk_label_number > 0 && (
                      <Button color="green" size="large" className="KioskLabelComposite" onClick={this.toggleKioskLabelModal}>
                        <FontAwesomeIcon icon={faTag} />
                        <span>{appointment.kiosk_label_number}</span>
                      </Button>
                    )}

                    {location.is_car_in_shop_print_enabled && (
                      <Button basic size="large" className="KeyLabelPrintCompositeIcon" onClick={this.handleOpenCarInShopPrintModal}>
                        <FontAwesomeIcon icon={faPrint} />
                        <FontAwesomeIcon icon={faKey} />
                      </Button>
                    )}

                    <Button.Group className="AppointmentNoteButtons" basic>
                      <Button
                        disabled={!this.props.authState.user.acl["appointment-notes-wo"]?.some(({ activity }) => activity === "create" || activity === "update")}
                        onClick={() => this.handleNoteSelected(APPOINTMENT_NOTE_TYPES.WO)}
                        size="large"
                      >
                        <FontAwesomeIcon icon={faFileAlt} color="#4283CA" />
                      </Button>
                      <Dropdown
                        basic
                        floating
                        className="button icon AppointmentNoteDropdown"
                        options={this.getNoteDropdownOptions()}
                        onChange={(_e, data) => this.handleNoteSelected(data.value)}
                        selectOnBlur={false}
                        direction="right"
                        trigger={<></>}
                        value={null}
                      />
                    </Button.Group>

                    {this.renderSpecialIndicators(appointment, isManufacturer || isMechanic)}

                    {/* Maybe on close cancel get requests if there are any, for example when an answer is updated and the page is immediately closed, there is no need to fetch the checks again */}

                    {!isCustomerCommunicationVisible &&
                      (appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_SENT ||
                        appointment.customcom_status === COMMUNICATION_STATUSES.REPAIR_OVERVIEW_OPENED) && (
                        <Button basic className="large icon download-pdf" onClick={this.handleDownloadPdfCarReadySent}>
                          <Icon name="file pdf" />
                        </Button>
                      )}

                    {DASH_CONFIG.desk_check_in_enabled && !deskCheckinDisabled && currentUser.is_counter_tablet_user && (
                      <Can I="create" the="desk_checkin">
                        <Button basic size="large" onClick={this.handleOpenRegisterTabletModal}>
                          <FontAwesomeIcon icon={faTabletAndroidAlt} />
                        </Button>
                      </Can>
                    )}

                    {!isCustomerCommunicationVisible &&
                      ((DASH_CONFIG.repair_overview_enabled && isCarReadyOrQualityCheckDone) ||
                        (DASH_CONFIG.diagnose_overview_enabled &&
                          !isCarReadyOrQualityCheckDone &&
                          ![APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CAR_IN_SHOP].includes(appointment.appointment_status_identifier))) && (
                        <Can I={["create_comm", "update_comm", "create_post_comm", "update_post_comm"]} the="customcom">
                          <Button.Group basic>
                            <Button
                              key="customer_comm_status"
                              basic
                              size="large"
                              disabled={
                                appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CUSTOMER_OK ||
                                appointment.appointment_status_identifier === APPOINTMENT_STATUSES.HANDLE_CHECK_IN ||
                                appointment.appointment_status_identifier === APPOINTMENT_STATUSES.CHECK_IN_DONE ||
                                isManufacturer
                              }
                              onClick={this.handleCustomerCommunication}
                            >
                              {this.getCustomerCommStatus(appointment) && <FontAwesomeIcon className="-margin-right-10" icon={this.getCustomerCommStatus(appointment)} />}
                              <FontAwesomeIcon icon={faEnvelope} />
                            </Button>
                            {appointment.customcom_receptionist_key && (
                              <Button basic size="large" onClick={() => this.handleOpenCC(appointment.customcom_receptionist_key)}>
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </Button>
                            )}
                          </Button.Group>
                        </Can>
                      )}

                    {[APPOINTMENT_STATUSES.NEW_CAR, APPOINTMENT_STATUSES.CAR_IN_SHOP, APPOINTMENT_STATUSES.HANDLE_CHECK_IN, APPOINTMENT_STATUSES.CHECK_IN_DONE].includes(
                      appointment.appointment_status_identifier
                    ) && (
                      <Can I="cancel" the="appointments">
                        <Button color="red" onClick={this.handleToggleOpenCancelAppointmentConfirm} className="appointment-cancel-button">
                          <i className="material-icons">cancel</i>
                        </Button>
                      </Can>
                    )}
                  </>
                )}

                {this.props.isURLHandler && this.props.authState.isQrLogin && (
                  <Button
                    color="green"
                    size="large"
                    onClick={() => {
                      const { countUnsavedChanges } = this.state;
                      if (countUnsavedChanges === 0) {
                        this.props.logout();
                      } else {
                        this.setState({ isUnsavedConfirmVisible: true });
                      }
                    }}
                    className="-margin-left-5"
                  >
                    {t("logout").message || "Logout"}
                  </Button>
                )}

                {!this.props.isURLHandler && (
                  <Button
                    color="green"
                    size="large"
                    onClick={() => {
                      const { countUnsavedChanges } = this.state;
                      if (countUnsavedChanges === 0) {
                        onClose();
                      } else {
                        this.setState({ isUnsavedConfirmVisible: true });
                      }
                    }}
                    className="-margin-left-5"
                  >
                    {t("close").message}
                  </Button>
                )}
              </div>
            </div>
            {this.renderAlert()}
            {this.renderAlertSuccessCopy()}
            <div className="AppointmentDetail__content__inner">
              {statusHistory && <AppointmentStatusTimeline location={this.props.location} history={statusHistory} checks={appointmentChecks} />}
              <AppointmentSnoozedItems appointmentId={appointment.id} />

              {dealer.advised_critical_history_enabled && (
                <AppointmentAdvisedCriticalHistory
                  appointment={appointment}
                  location={this.props.location}
                  dashboardConfig={DASH_CONFIG}
                  onMediaClick={this.handleMediaClick}
                />
              )}

              <AppointmentLists
                dms_price_enabled={location.dms_price_enabled}
                onInterventionDelete={this.handleDeleteIntervention}
                isDeletingIntervention={this.state.isDeletingIntervention}
                appointment={appointment}
                dashboardConfig={DASH_CONFIG}
                interventions={appointment.interventions}
                checks={appointmentChecks}
                onInterventionAnswerUpdate={this.handleInterventionAnswerUpdate}
                onChecklistAnswerUpdate={this.handleChecklistAnswerUpdate}
                onMediaClick={this.handleMediaClick}
                onShowAddInterventionClick={this.handleShowAddIntervention}
                isEditable={isEditable}
                isPriceEditable={isPriceEditable}
                currentUser={this.props.currentUser}
                openCarDetail={onRegistrationClick}
                isCustomerCommunicationVisible={false}
                onHandleCheckInCom={this.handleCheckInCom}
                onHandleKeyLockerRemark={this.handleKeyLockerRemark}
                onHandleKioskRemark={this.handleKioskRemark}
                onHandleCustomCom={this.handleCustomCom}
                selectedCustomerComm={selectedCustomerComm}
                location={this.props.location}
              />
            </div>

            {this.renderAddIntervention()}
            {this.renderConvertToIntervention()}

            {isCustomerCommunicationVisible && renderCustomerCommunication()}
            {this.renderCounterTabletModal()}
            {this.state.deskCheckinModalOpen && (
              <DeskCheckinModal
                selectedDeskComm={this.state.selectedDeskComm}
                appointment={this.state.appointment}
                desk_communications={appointment.desk_communications}
                onClose={this.handleCloseDeskCheckinModal}
              />
            )}
            {this.state.isDeskCommunicationVisible && (
              <DeskCheckInQuestionModal
                dms_price_enabled={location.dms_price_enabled}
                onInterventionDelete={this.handleDeleteIntervention}
                isDeletingIntervention={this.state.isDeletingIntervention}
                appointment={appointment}
                dashboardConfig={DASH_CONFIG}
                interventions={appointment.interventions}
                checks={appointmentChecks}
                selectedDeskComm={this.state.selectedDeskComm}
                onChangeDeskCommunication={this.handleChangeDeskCommunication}
                getDeskComTotalPrice={this.getDeskComTotalPrice}
                onInterventionAnswerUpdate={this.handleInterventionAnswerUpdate}
                onChecklistAnswerUpdate={this.handleChecklistAnswerUpdate}
                onMediaClick={this.handleMediaClick}
                onShowAddInterventionClick={this.handleShowAddIntervention}
                isEditable={isEditable}
                isPriceEditable={isPriceEditable}
                currentUser={this.props.currentUser}
                openCarDetail={onRegistrationClick}
                isDeskCommunicationVisible={true}
                onHandleKeyLockerRemark={this.handleKeyLockerRemark}
                onHandleKioskRemark={this.handleKioskRemark}
                location={this.props.location}
                isCarReadyOrQualityCheckDone={isCarReadyOrQualityCheckDone}
                renderDeskCheckInModal={this.renderDeskCheckInModal}
                handleCloseDeskCheckinQuestionsModal={this.handleCloseDeskCheckinQuestionsModal}
              />
            )}
          </div>

          {this.renderCarReadyConfirmation()}
          {this.renderOnlineCheckinConfirmation()}
          {this.renderUnsavedConfirmation()}
          {this.renderImageGallery()}
          {this.renderCustomerModal()}
          {this.renderCarInShopPrintModal()}
          {this.renderEnterCurrentMileageModal()}
          {this.renderConfirmDetachLabelModal()}

          {(this.props.loadingCarDetail || this.state.refreshingDMS) && (
            <div className="Loader">
              <Loader type="Oval" color="#46923d" height="100" width="100" />
            </div>
          )}
          <CustomConfirm
            type="danger"
            confirmReasons={this.state.confirmReasons}
            allowCustomReason
            isOpen={this.state.isOpenConfirmAppointmentCancel}
            handleCancel={this.handleToggleOpenCancelAppointmentConfirm}
            handleConfirm={this.handleCancelAppointment}
            isLoading={this.state.isCancelingAppointment}
            confirmMsg={this.props.t("confirm_cancel_appointment_message").message || "Are you sure that you want to cancel this appointment?"}
            error={this.state.cancelAppointmentError}
          />
        </div>
      </SemanticModal>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth, globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    addAppointmentAttachment: attachment => dispatch(addAppointmentAttachment(attachment)),
    deleteAppointmentAttachment: url => dispatch(deleteAppointmentAttachment(url)),
    addIntervention: intervention => dispatch(addIntervention(intervention)),
    deleteIntervention: id => dispatch(deleteIntervention(id)),
    logout: () => dispatch(logout()),
  };
};

export default withTranslation()(visorify(connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail)));

const getDriverItem = ({ driverID, driver, onShowCustomerDetail, typeText, type, isMechanic }) =>
  (driver.title || driver.initials || driver.surname || driver.company) && (
    <li className="-customer-info" onClick={() => !isMechanic && driverID && onShowCustomerDetail(driverID)}>
      <span className="-detail-type">{typeText}</span>
      <span className="-detail-value">
        <Icon name="info circle" className="CustomerInfoTrigger" />
        {_getName(driver)}
      </span>
    </li>
  );

function _getName(d) {
  let name = d.title || d.initials || "";

  if (d.firstname) name += " " + d.firstname;

  if (d.surname) name += " " + d.surname;

  name = name.trim();

  if (!name && d.company) {
    name = d.company;
  }

  return name;
}
