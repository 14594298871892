import React, { Component } from "react";
import { Icon, Form, Checkbox, Segment, Header, Dropdown } from "semantic-ui-react";
import moment from "moment";

import UserInput from "../UserInput";
import Versions from "./Versions";

class GeneralSettings extends Component {
  state = {
    timeslots: [],
  };

  componentDidMount() {
    const { location } = this.props;
    let slots;

    if (!location.timeslots) {
      slots = [
        {
          active: false,
          deadline: moment("12:00", "HH:mm"),
          deadlineTime: "12:00",
        },
        {
          active: false,
          deadline: moment("13:00", "HH:mm"),
          deadlineTime: "13:00",
        },
        {
          active: false,
          deadline: moment("14:00", "HH:mm"),
          deadlineTime: "14:00",
        },
        {
          active: false,
          deadline: moment("15:00", "HH:mm"),
          deadlineTime: "15:00",
        },
      ];
    } else {
      slots = location.timeslots;
      slots.map(s => (s.deadlineTime = moment(s.deadline).format("HH:mm")));
    }
    this.setState({ timeslots: slots });
  }

  handleTimeslotCheckboxChange = (checked, i) => {
    let { location, handleChange } = this.props;
    let { timeslots } = this.state;
    timeslots[i].active = checked;
    location.timeslots = timeslots;
    handleChange(location);
  };

  handleTimeslotChanged = (e, i) => {
    let { location, handleChange } = this.props;
    let { timeslots } = this.state;
    const time = e.target.value;
    let t = moment(time, "HH:mm");
    t.set("year", 2017);
    t.set("month", 1);
    t.set("date", 0);
    timeslots[i].deadline = t.format();
    timeslots[i].deadlineTime = time;
    location.timeslots = timeslots;
    handleChange(location);
  };

  handleCheckboxChange = (e, { name, checked }) => {
    let { location, handleChange } = this.props;

    if (name === "is_next_km_and_next_date_optional" && !checked) {
      location["hide_next_km_and_next_date"] = false;
    }

    location[name] = checked;
    handleChange(location);
  };

  handleIncludeVAT = () => {
    let { location, handleChange } = this.props;
    this.setState(
      prevState => ({
        includeVAT: !prevState.includeVAT,
      }),
      () => {
        location.include_vat = this.state.includeVAT;
        handleChange(location);
      }
    );
  };

  handleVATChange = evt => {
    let { location, handleChange } = this.props;
    location.vat = evt.target.value - 0;
    handleChange(location);
  };

  handleNumberInputChange = (_e, { name, value }) => {
    const { location, handleChange } = this.props;

    if (isNaN(value)) return;

    handleChange({ ...location, [name]: Number(value) });
  };

  handleNameColumnSelection = (evt, data) => {
    let { location, handleChange } = this.props;
    location.default_customer = data.value;
    handleChange(location);
  };

  render() {
    const { location, t, isAdmin, versions, isSubmit, handleChange } = this.props;

    return (
      location && (
        <div>
          {isAdmin && <Versions isSubmit={isSubmit} versions={versions} location={location} handleChange={handleChange} isAdmin={isAdmin} t={t} {...this.props} />}
          <Segment>
            <Header as="h5">
              <Icon name="settings" />
              <Header.Content>{t("general_settings").message || "Instellingen algemeen"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form>
                <Form.Field>
                  <Checkbox
                    checked={location.accept_network_metrics}
                    name="accept_network_metrics"
                    label={t("accept_network_metrics").message || "Accept Network Metrics"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox checked={location.date_visible} name="date_visible" label={t("Date").message || "Date"} onChange={this.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                  <Checkbox checked={location.apk_visible} name="apk_visible" label={t("APK Date").message || "APK Date"} onChange={this.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                  <Checkbox checked={location.hu_visible} name="hu_visible" label={t("hu_date").message || "HU Date"} onChange={this.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={location.is_customer_answered_checkin_mandatory}
                    name="is_customer_answered_checkin_mandatory"
                    label={t("customer_sign_mandatory").message || "Customer sign mandatory"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
              </Form>
            </Segment.Inline>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="settings" />
              <Header.Content>{t("visible_on_main_screen").message || "Zichtbaar op hoofdscherm"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form>
                <Form.Field>
                  <Dropdown
                    onChange={this.handleNameColumnSelection}
                    value={location.default_customer}
                    selection
                    options={[
                      {
                        key: "driver_visible",
                        text: t("driver_visible").message || "Driver Visible",
                        value: "driver",
                      },
                      {
                        key: "contractor_visible",
                        text: t("contractor_visible").message || "Contractor Visible",
                        value: "contractor",
                      },
                      {
                        key: "owner_visible",
                        text: t("owner_visible").message || "Owner Visible",
                        value: "owner",
                      },
                    ]}
                  />
                </Form.Field>
              </Form>
            </Segment.Inline>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="settings" />
              <Header.Content>{t("visible_on_detailpage").message || "Zichtbaar op wo detail pagina"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form>
                <Form.Field>
                  <Checkbox
                    checked={location.diagnose_status_visible}
                    name="diagnose_status_visible"
                    label={t("info_button").message || "Info Button"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={location.car_ready_btn_visible}
                    name="car_ready_btn_visible"
                    label={t("car_ready_btn").message || "Car ready button"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox checked={location.dp_driver_visible} name="dp_driver_visible" label={t("driver").message || "Driver"} onChange={this.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={location.dp_contractor_visible}
                    name="dp_contractor_visible"
                    label={t("contractor").message || "Contractor"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox checked={location.dp_owner_visible} name="dp_owner_visible" label={t("owner").message || "Owner"} onChange={this.handleCheckboxChange} />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    checked={location.mcc_button_visible}
                    name="mcc_button_visible"
                    label={t("mcc_button").message || "MCC Button"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>

                <div>
                  <Form.Field>
                    <Checkbox
                      checked={location.service_box_visible_on_car}
                      name="service_box_visible_on_car"
                      label={t("service_button_car_dashboard").message || "Service button car dashboard"}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      checked={location.service_box_visible_on_wo}
                      name="service_box_visible_on_wo"
                      label={t("service_button_wo_dashboard").message || "Service button WO dashboard"}
                      onChange={this.handleCheckboxChange}
                    />
                  </Form.Field>
                </div>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.dms_price_enabled}
                    name="dms_price_enabled"
                    label={t("dms_price_enabled").message || "DMS intervention price"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.dms_billing_enabled}
                    name="dms_billing_enabled"
                    label={t("activate_dms_billing").message || "Activate DMS billing"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.include_vat}
                    name="include_vat"
                    label={t("including_price_vat").message || "Including price with VAT %"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.appointment_detail_users_indicator_enabled}
                    name="appointment_detail_users_indicator_enabled"
                    label={t("active_users_indicator").message || "Active users indicator"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.is_car_in_shop_print_enabled}
                    name="is_car_in_shop_print_enabled"
                    label={t("car_in_shop_print").message || "Car in shop print"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: "1em" }}>
                  <Checkbox
                    checked={location.is_editing_question_videos_enabled}
                    name="is_editing_question_videos_enabled"
                    label={t("edit_question_videos").message || "Edit question videos"}
                    onChange={this.handleCheckboxChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t("enter_your_vat").message || "Enter Yout VAT %"}</label>
                  <UserInput
                    value={location.vat}
                    disabled={!location.include_vat}
                    onChange={this.handleVATChange}
                    placeholder={t("percentage").message || "Percentage %"}
                  />
                </Form.Field>
              </Form>
            </Segment.Inline>
          </Segment>
          <Segment>
            <Header as="h5">
              <Icon name="clock outline" />
              <Header.Content>{t("parts_load_order_times").message || "Parts / Load order times"}</Header.Content>
            </Header>
            <Segment.Inline>
              <Form>
                {this.state.timeslots &&
                  this.state.timeslots.map((timeslot, i) => {
                    return (
                      <Form.Field key={i}>
                        <Form.Group>
                          <Form.Field width="1">
                            <Checkbox checked={timeslot.active} name="timeslot" onChange={(e, data) => this.handleTimeslotCheckboxChange(data.checked, i)} />
                          </Form.Field>
                          <Form.Field width="7">
                            <UserInput
                              type="time"
                              value={timeslot.deadlineTime}
                              className="timepicker"
                              step="300"
                              onChange={e => {
                                this.handleTimeslotChanged(e, i);
                              }}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form.Field>
                    );
                  })}
              </Form>
            </Segment.Inline>
          </Segment>
          {isAdmin && (
            <Segment>
              <Header as="h5">
                <Icon name="settings" />
                <Header.Content>{t("admin_settings").message || "Admin algemeen"}</Header.Content>
              </Header>
              <Segment.Inline>
                <Form>
                  <Form.Field>
                    <Checkbox checked={location.video_enabled} name="video_enabled" label={t("video").message || "Video"} onChange={this.handleCheckboxChange} />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox checked={location.schedule_enabled} name="schedule_enabled" label={t("snooze").message || "Snooze"} onChange={this.handleCheckboxChange} />
                  </Form.Field>
                </Form>
              </Segment.Inline>

              <Segment>
                <Header as="h5">
                  <Header.Content>{t("next_km_next_date").message || "Next km and next date"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_next_km_and_next_date_optional}
                        name="is_next_km_and_next_date_optional"
                        label={t("is_next_km_and_next_date_optional").message || "Next km and next date optional"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        checked={location.hide_next_km_and_next_date}
                        name="hide_next_km_and_next_date"
                        disabled={!location.is_next_km_and_next_date_optional}
                        label={t("hide_next_km_and_next_date").message || "Hide next km and next date"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>
            </Segment>
          )}
          {isAdmin && (
            <>
              <Segment>
                <Header as="h5">
                  <Icon name="retweet" />
                  <Header.Content>{t("recurring_car").message || "Recurring car"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <label>{t("recurring_duration").message || "Recurring duration (Weeks)"}</label>
                      <UserInput
                        value={location.recurring_car_duration}
                        onChange={this.handleNumberInputChange}
                        name="recurring_car_duration"
                        placeholder={t("recurring_duration").message || "Recurring duration (Weeks)"}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>

              <Segment>
                <Header as="h5">
                  <Icon name="settings" />
                  <Header.Content>{t("new_appointment_settings").message || "Neue apfspraak algemeen"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        checked={location.first_name_optional}
                        name="first_name_optional"
                        label={t("first_name_optional").message || "First name optional"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>
              <Segment>
                <Header as="h5">
                  <Icon name="settings" />
                  <Header.Content>{t("pin_appointments").message || "Pin appointments"}</Header.Content>
                </Header>
                <Segment.Inline>
                  <Form>
                    <Form.Field>
                      <Checkbox
                        checked={location.automatically_pin_appointments}
                        name="automatically_pin_appointments"
                        label={t("automatically_pin_appointments").message || "Automatically pin appointments"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_manually_pinning_appointments_enabled}
                        name="is_manually_pinning_appointments_enabled"
                        label={t("allow_manual_pinning_red_pin").message || "Allow manual pinning (Red pin)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        checked={location.is_automatically_pinned_appointments_readonly}
                        name="is_automatically_pinned_appointments_readonly"
                        label={t("set_automatic_pin_as_read_only_black_pin").message || "Set automatic pin as read-only (Black pin)"}
                        onChange={this.handleCheckboxChange}
                      />
                    </Form.Field>
                  </Form>
                </Segment.Inline>
              </Segment>
            </>
          )}
        </div>
      )
    );
  }
}

export default GeneralSettings;
