import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import AlertContainer from "react-alert";
import DatePicker from "react-datepicker";
import moment from "moment";

import Can from "../Can";
import { setAlert } from "../App/store";
import { CustomConfirm, SubHeader, UserInput, UserMenuActionsPortal } from "../../components";

import Service from "./service";

import "./ParkingGateCodes.scss";

const defaultSelectedParkingGateCode = {
  start_at: new Date(),
  end_at: new Date(),
  pin: "",
};

const PAGE_LENGTH = 20;

export class ParkingGateCodes extends Component {
  state = {
    parkingGateCodes: [],
    selectedParkingGateCode: { ...defaultSelectedParkingGateCode },
    isModalOpen: false,
    isLoading: false,
    isDeleteConfirmationOpen: false,
    isDeleting: false,
    validationError: false,
    parkingGateCodeId: null,
  };

  componentDidMount() {
    this.listParkingGateCodes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.globalState.selectedLocation.id !== this.props.globalState.selectedLocation.id) this.listParkingGateCodes();
  }

  renderAlert = () => {
    const props = {
      offset: 20,
      position: "top right",
      theme: "light",
      time: 2000,
      transition: "fade",
    };

    return <AlertContainer ref={a => (this.msg = a)} {...props} />;
  };

  listParkingGateCodes = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await Service.listParkingGateCodes({ dealer_location_id: this.props.globalState.selectedLocation.id });

        this.setState({ parkingGateCodes: response?.data?.data?.parking_gate_code || [], isLoading: false });
      } catch (error) {
        console.error("Error getting parking gate codes:", error);
        this.setState({ isLoading: false }, () => {
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("failed_to_get_parking_gate_codes").message || "Failed to get parking gate codes";
          this.msg.show(errorMsg, { type: "error" });
        });
      }
    });
  };

  handleDateChange = (date, name) => {
    this.setState(state => ({ selectedParkingGateCode: { ...state.selectedParkingGateCode, [name]: date } }));
  };

  handleOpenAddEditModal = selectedParkingGateCode => {
    this.setState({ selectedParkingGateCode: selectedParkingGateCode ? selectedParkingGateCode : { ...defaultSelectedParkingGateCode }, isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ selectedParkingGateCode: { ...defaultSelectedParkingGateCode }, isModalOpen: false });
  };

  handleShowDeleteConfirmation = parkingGateCodeId => {
    this.setState({ isDeleteConfirmationOpen: true, parkingGateCodeId });
  };

  handleCloseDeleteConfirmation = () => {
    this.setState({ isDeleteConfirmationOpen: false, parkingGateCodeId: null });
  };

  handleDeleteCodes = () => {
    this.setState({ isDeleting: true }, async () => {
      try {
        await Service.deleteParkingGateCode({ parking_gate_code_id: this.state.parkingGateCodeId });

        const parkingGateCodes = this.state.parkingGateCodes.filter(p => p.id !== this.state.parkingGateCodeId);

        this.setState({ isDeleting: false, parkingGateCodes, isDeleteConfirmationOpen: false });
      } catch (error) {
        console.error("Error deleting parking gate code", error);
        this.setState({ isDeleting: false }, () => {
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("failed_to_delete_parking_gate_codes").message || "Failed to delete parking gate";
          this.msg.show(errorMsg, { type: "error" });
        });
      }
    });
  };

  handleValidate = ({ start_at, end_at, pin }) => {
    return pin && start_at && end_at && moment(end_at).isSameOrAfter(moment(start_at));
  };

  handleSave = () => {
    const { selectedParkingGateCode } = this.state;

    if (!this.handleValidate(selectedParkingGateCode)) {
      this.setState({ validationError: true });
      return;
    }

    this.setState({ isSaving: true }, async () => {
      try {
        const parsedParkingGateCode = {
          ...selectedParkingGateCode,
          start_at: moment.utc(selectedParkingGateCode.start_at).startOf("day").toISOString(),
          end_at: moment.utc(selectedParkingGateCode.end_at).startOf("day").toISOString(),
        };
        if (selectedParkingGateCode.id) {
          await Service.updateParkingGateCode(parsedParkingGateCode);

          const parkingGateCodes = this.state.parkingGateCodes.map(p => {
            if (p.id === parsedParkingGateCode.id) return parsedParkingGateCode;
            return p;
          });

          this.setState({ isSaving: false, parkingGateCodes, isModalOpen: false });
        } else {
          const response = await Service.createParkingGateCode({ ...parsedParkingGateCode, dealer_location_id: this.props.globalState.selectedLocation.id });

          if (response?.data?.data?.parking_gate_code_id) {
            this.setState(state => ({
              parkingGateCodes: [...state.parkingGateCodes, { ...parsedParkingGateCode, id: response.data.data.parking_gate_code_id }],
              isSaving: false,
              isModalOpen: false,
            }));
          } else throw Error("Failed to create parking gate code");
        }
      } catch (error) {
        console.error("Error saving parking gate code", error);
        this.setState({ isSaving: false }, () => {
          const errorMsg =
            typeof error === "string"
              ? error
              : error.message
              ? error.message
              : error.response?.data?.errors?.length
              ? error.response.data.errors[0]
              : this.props.t("failed_to_save_parking_gate_codes").message || "Failed to save parking gate";
          this.msg.show(errorMsg, { type: "error" });
        });
      }
    });
  };

  renderTable = () => {
    const { parkingGateCodes, isLoading, isDeleting } = this.state;
    const { t } = this.props;

    if (isLoading && !parkingGateCodes?.length) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading_parking_gate_codes").message || "Loading Parking Gate Codes"}</section>
        </div>
      );
    }

    if (!parkingGateCodes?.length) return <div className="Table__no-results">{t("no_codes_found").message || "No codes found"}</div>;

    parkingGateCodes.sort((a, b) => new Date(a.start_at) - new Date(b.start_at) || new Date(a.end_at) - new Date(b.end_at) || a.id - b.id);

    return (
      <ReactTable
        className="ReactTable ParkingGateCodes__table -floated-table -contained-large"
        data={parkingGateCodes || []}
        showPagination={parkingGateCodes.length > PAGE_LENGTH}
        defaultPageSize={parkingGateCodes.length < PAGE_LENGTH ? parkingGateCodes.length : PAGE_LENGTH}
        pageSize={parkingGateCodes.length < PAGE_LENGTH ? parkingGateCodes.length : PAGE_LENGTH}
        resizable={false}
        showPageSizeOptions={false}
        sortable={false}
        minRows={0}
        loading={isLoading}
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: t("start_at").message || "Start At",
            id: "start_at",
            width: 250,
            accessor: d => moment(d.start_at).format("DD-MM-YYYY"),
          },
          {
            Header: t("end_at").message || "End At",
            id: "end_at",
            width: 250,
            accessor: d => moment(d.end_at).format("DD-MM-YYYY"),
          },
          {
            Header: "PIN",
            id: "pin",
            width: 220,
            accessor: "pin",
          },
          {
            id: "actions",
            Cell: row => {
              return (
                <div className="ParkingGateCodes__table-actions">
                  <Can I="update" the="parking_gate_code">
                    <Button size="mini" icon="edit" color="blue" disabled={isLoading} onClick={() => this.handleOpenAddEditModal(row.original)} />
                  </Can>
                  <Can I="delete" the="parking_gate_code">
                    <Button
                      size="mini"
                      icon="trash"
                      color="red"
                      disabled={isLoading || isDeleting}
                      loading={isLoading || isDeleting}
                      onClick={() => this.handleShowDeleteConfirmation(row.original.id)}
                    />
                  </Can>
                </div>
              );
            },
          },
        ]}
      />
    );
  };

  renderDeleteConfirmation = () => {
    const { isDeleteConfirmationOpen, isDeleting } = this.state;
    const { t } = this.props;

    return (
      <CustomConfirm
        type="danger"
        isOpen={isDeleteConfirmationOpen}
        handleConfirm={this.handleDeleteCodes}
        handleCancel={this.handleCloseDeleteConfirmation}
        confirmMsg={t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        isLoading={isDeleting}
      />
    );
  };

  renderAddEditModal = () => {
    const { isModalOpen, selectedParkingGateCode, validationError, isSaving } = this.state;
    const { t } = this.props;

    return (
      <Modal className="ParkingGateCodes__modal" open={isModalOpen} onClose={this.handleCloseModal} size="small">
        <Modal.Header>
          <div>{t("parking_gate_code").message || "Parking Gate Code"}</div>
        </Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={validationError && !selectedParkingGateCode.start_at}>
                <label htmlFor="start_at">{t("start_at").message || "Start At"}</label>
                <DatePicker
                  id="start_at"
                  dateFormat="dd-MM-yyyy"
                  placeholderText={t("start_at").message || "Start at"}
                  selected={selectedParkingGateCode.start_at && moment(selectedParkingGateCode.start_at).toDate()}
                  onChange={date => this.handleDateChange(date, "start_at")}
                  onChangeRaw={e => e.preventDefault()}
                  showMonthDropdown
                  showYearDropdown
                />
              </Form.Field>

              <Form.Field error={validationError && !selectedParkingGateCode.end_at}>
                <label htmlFor="end_at">{t("end_at").message || "End At"}</label>
                <DatePicker
                  id="end_at"
                  dateFormat="dd-MM-yyyy"
                  placeholderText={t("end_at").message || "End at"}
                  selected={selectedParkingGateCode.end_at && moment(selectedParkingGateCode.end_at).toDate()}
                  minDate={selectedParkingGateCode.start_at ? moment(selectedParkingGateCode.start_at).toDate() : null}
                  onChange={date => this.handleDateChange(date, "end_at")}
                  onChangeRaw={e => e.preventDefault()}
                  showMonthDropdown
                  showYearDropdown
                />
              </Form.Field>
            </Form.Group>

            <Form.Field error={validationError && !selectedParkingGateCode.pin}>
              <UserInput
                type="text"
                placeholder="PIN"
                value={selectedParkingGateCode.pin}
                onChange={(_event, data) => this.setState(state => ({ selectedParkingGateCode: { ...state.selectedParkingGateCode, pin: data.value } }))}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="grey" disabled={isSaving} onClick={this.handleCloseModal}>
            {t("cancel").message || "Cancel"}
          </Button>

          <Button color="green" onClick={() => this.handleSave()} loading={isSaving} disabled={isSaving}>
            <Icon name="checkmark" /> {t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;

    return (
      <div className="ParkingGateCodes">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.listParkingGateCodes} />
        </UserMenuActionsPortal>
        <SubHeader>
          <Grid.Column width={14}>
            <h1>{t("parking_gate_codes").message || "Parking Gate Codes"}</h1>
          </Grid.Column>
          <Grid.Column width={2}>
            <Can I="create" the="parking_gate_code">
              <Button color="green" icon labelPosition="left" disabled={isLoading} fluid onClick={() => this.handleOpenAddEditModal()}>
                <Icon name="add" /> {t("add").message || "Add"}
              </Button>
            </Can>
          </Grid.Column>
        </SubHeader>

        {this.renderTable()}
        {this.renderAddEditModal()}
        {this.renderDeleteConfirmation()}
        {this.renderAlert()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ParkingGateCodes));
