import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getMediums(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/mediums/list", requestData, this.requestConfig());
  }

  static getDefaultTemplates() {
    return axios.get(ENV.dealerBaseUrl + "/communication/mediums/list_default", this.requestConfig());
  }

  static getDetail(dealer_location_id) {
    return axios.post(ENV.dealerBaseUrl + "/communication/settings/read", { dealer_location_id }, this.requestConfig());
  }

  static updateMedium(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/mediums/update", requestData, this.requestConfig());
  }

  static duplicateMedium(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/mediums/duplicate", requestData, this.requestConfig());
  }

  static deleteDuplicateMedium(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/communication/mediums/delete", requestData, this.requestConfig());
  }

  static uploadEmailTemplateImages(requestData) {
    return axios.post(ENV.apiBaseB + "/files/cc_emails_pictures", requestData, this.requestConfig());
  }
}

export default Service;
